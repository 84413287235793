import React from 'react';
import { useHistory } from 'react-router-dom';
import { ForgotPassword } from '../../forms';
import {
  Button,
} from '..';

import scss from './forgotpass.module.scss';

interface Props {
  handleSubmit: any;
  forgotpassSucess: any;
  forgotpassError: any;
}

const ForgotPass: React.FC<Props> = (props: Props) => {
  const { handleSubmit, forgotpassSucess, forgotpassError } = props;
  const history = useHistory();
  const handleFormSubmit = (data: any) => {
    handleSubmit(data);
  };
  return (
    <section>
      {forgotpassSucess ? (
        <>
          <h2 className={scss.sign_text}>Reset Link</h2>
          <p className={scss.details_text}>
            An email has been sent to your registered email ID.
            Please follow the instructions to reset your password.
          </p>
          <Button onClick={() => history.push('/')}>
            Back to Login
          </Button>
        </>
      ) : (
        <ForgotPassword
          handleFormSubmit={handleFormSubmit}
          forgotpassError={forgotpassError}
        />
      )}
    </section>
  );
};

export default ForgotPass;
