import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  ValidationError,
  CircularProgressLoader,
} from 'components';
import {
  RolesManagementService,
  SubAdminManagementService,
} from 'services';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import commonStyles from 'components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import scss from './editsubadmin.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  handleSubmit: any;
  error: any;
  id: any;
  loader: any;
}

const EditSubAdminForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    loader,
    error,
    id,
    intl,
  } = props;
  const history = useHistory();
  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const editSubAdminSchema = yup.object().shape({
    name: yup.string().required('Please fill all the required fields'),
    email: yup.string()
      .required('Please fill all required fields'),
    phoneNumber: yup.string()
      .required('Please fill all required fields'),
    countryCode: yup.string().required('Plese fill all required fields'),
    roleId: yup.string()
      .required('Please fill all required fields'),
  }, []);
  const formProps = useForm({
    resolver: yupResolver(editSubAdminSchema),
  });

  React.useEffect(() => {
    Promise.all([
      SubAdminManagementService
        .getDetail(id),
      RolesManagementService
        .getAllRoles(),
    ]).then((responses: any) => {
      setLoading(false);
      formProps.reset({
        name: responses[0].data.data.name,
        email: responses[0].data.data.email,
        phoneNumber: responses[0].data.data.phoneNo,
        roleId: responses[0].data.data.roleId,
        countryCode: `+${responses[0].data.data.countryCode}`,
      });
      const data: any = [];
      responses[1].data.data.forEach((ele: any) => {
        // eslint-disable-next-line no-underscore-dangle
        data.push({ key: ele._id, label: ele.role });
      });
      setRoles(data);
    });
  }, [formProps, id]);

  return (
    <>
      {loading ? (
        <div>
          <CircularProgressLoader />
        </div>
      ) : (
        <div className={classNames(commonStyles.cardBg, scss.container)}>
          <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleSubmit)}>
            <h2 className={scss.sign_text}>
              Edit Details
            </h2>
            <div className="flex_row">
              <div className="flex_col_sm_6">
                <FieldTextInput
                  id="name"
                  name="name"
                  formName="subAdmin"
                  className="textInput"
                  type="text"
                  formProps={formProps}
                  rootClassName={scss.passwordInput}
                  label="*Name"
                />
              </div>
              <div className="flex_col_sm_6">
                <FieldTextInput
                  id="email"
                  name="email"
                  formName="subAdmin"
                  className="textInput"
                  type="text"
                  formProps={formProps}
                  rootClassName={scss.passwordInput}
                  label="*Email Id"
                />
              </div>
              <div className="flex_col_sm_6">
                <FieldTextInput
                  className="phoneNumber2"
                  id="phoneNo"
                  name="phoneNumber"
                  formName="UpdateProfile"
                  type="text"
                  // defaultValueCc={formProps.watch('countryCode')}
                  formProps={formProps}
                  label="Mobile Number"
                />
              </div>
              <div className="flex_col_sm_6">
                <div className={scss.userType}>
                  <FieldSelect
                    id="roleId"
                    name="roleId"
                    label="*Role"
                    variant={false}
                    formProps={formProps}
                    options={roles}
                    placeholder="Assign a role"
                  />
                </div>
              </div>
            </div>

            {Object.keys(formProps.formState.errors).length > 0
              && (
                <ValidationError
                  className={scss.validation_error}
                  error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
                />
              )}
            {Object.keys(formProps.formState.errors).length === 0
              && <ValidationError error={error} />}
            {loader ? <CircularProgressLoader /> : (
              <>
                <div className={scss.buttonWrapper}>
                  <Button buttonType={ButtonTypes.submit}>
                    Update
                  </Button>
                  <Button onClick={() => history.push('/subadminmanagement')}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(EditSubAdminForm);
