import React from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { CircularProgressLoader } from 'components';
import {
  Toolbar,
  Typography,
} from '@material-ui/core';
import scss from './datatable.module.scss';

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells_: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells_,
  } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
        </TableCell>
        {headCells_.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className={scss.table_spacing}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <>
                {headCell.label}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    // boxShadow: '0 0 20px #0000000d',
    // border: '1px solid #cccccc',
    // borderRadius: '0',
  },
  paper: {
    width: '100%',
    borderRadius: '0',
  },
  table: {
    minWidth: 750,
    background: '#fffff',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

}));

interface Props {
  rows_: any;
  headCells_: any;
  rowClick: any;
  selected: any;
  setSelected: any;
  setSelectedRows: any;
  page: any;
  rowsPerPage: any;
  handleChangePage: any;
  handleChangeRowsPerPage: any;
  handleSort: any;
  count: number;
  loading: boolean;
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <>
      {numSelected > 0 && (
        <Toolbar>
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected}
            {' '}
            selected
          </Typography>
        </Toolbar>
      )}
    </>
  );
};

const DataTable: React.FC<Props> = (props: Props) => {
  const {
    rows_,
    headCells_,
    rowClick,
    selected,
    setSelected,
    setSelectedRows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSort,
    count,
    loading,
  } = props;

  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dense, setDense] = React.useState(false);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleSort(isAsc ? 'desc' : 'asc', property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows_.map((n: any) => n.id);
      setSelected(newSelecteds);
      setSelectedRows(rows_);
      return;
    }
    setSelected([]);
    setSelectedRows([]);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows_.length}
              headCells_={headCells_}
            />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <CircularProgressLoader />
                  </TableCell>
                </TableRow>
              </TableBody>
            )
              : (
                <TableBody>
                  {rows_.length === 0
                    ? (
                      <>
                        <TableRow>
                          <TableCell>
                            <div className="headingWrapper">
                              <h3> No Record Found </h3>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <>
                        {rows_.map((row: any, index: any) => {
                          // const isItemSelected = isSelected(row.username.toString());
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={(event) => rowClick(event, row)}
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  inputProps={{ 'aria-labelledby': labelId }}
                                  checked={selected.includes(row.id)}
                                  style={{ color: '#5282F0' }}
                                />
                              </TableCell>
                              {
                                Object.keys(row).map((ele: any, i: any) => {
                                  if (ele !== 'id') return <TableCell key={i} align="center">{row[ele]}</TableCell>;
                                  return undefined;
                                })
                              }
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                  {/* {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={headCells_.length + 1} />
                  </TableRow>
                  )} */}
                </TableBody>
              )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
};

export default DataTable;
