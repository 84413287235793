import { TextField } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import ChannelReportDetails from 'components/ChannelReportDetails';
import classNames from 'classnames';
import {
  Button,
  CircularProgressLoader,
  Modal,
  ValidationError,
  PostCreated,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
} from '..';
import { getChannelInfo } from '../../actions';
import { ChannelInfoService } from '../../services';
import scss from './channelinfo.module.scss';
import profileImg from '../../assets/svg/profilepic.svg';
import deleteImg from '../../assets/svg/delete.svg';
import blockImg from '../../assets/svg/block.svg';
import exportImg from '../../assets/svg/export.svg';
import warning from '../../assets/svg/warning.svg';
import { dateFormatting } from '../../utils/dateMethods';
import unblockImg from '../../assets/svg/unblock.svg';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  channelInfo: any;
  id: any;
  fetchChannelInfo: any;
}

const ChannelInfo: React.FC<Props> = (props: Props) => {
  const {
    channelInfo,
    id,
    fetchChannelInfo,
  } = props;

  React.useEffect(() => {
    fetchChannelInfo(id);
  }, [fetchChannelInfo, id]);
  const history = useHistory();

  const [showModal, setShowModal] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showBlock, setShowBlock] = React.useState(false);
  const [sendNotification, setSendNotification] = React.useState(false);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [showEmailNotification, setShowEmailNotification] = React.useState(false);
  const [showSMSNotification, setShowSMSNotification] = React.useState(false);
  const [showPushNotification, setShowPushNotification] = React.useState(false);
  const [emailTitle, setEmailTitle] = React.useState('');
  const [blockedSucessfully, setBlockedSucessfully] = React.useState(false);
  const [pushNotification, setPushNotification] = React.useState({
    title: '',
    message: '',
  });
  const [emailNotificationError, setEmailNotificationError] = React.useState({
    title: false,
    message: '',
  });
  const [smsNotificationError, setSMSNotificationError] = React.useState({
    message: '',
  });
  const [pushNotificationError, setPushNotificationError] = React.useState({
    title: false,
    message: '',
  });
  const [message, setMessage] = React.useState('');

  const handleModalClose = () => {
    setShowDelete(false);
    setShowModal(false);
    setShowBlock(false);
    setShowPushNotification(false);
    setShowSMSNotification(false);
    setShowEmailNotification(false);
    setSendNotification(false);
    setPushNotificationError({ title: false, message: '' });
    setEmailNotificationError({ title: false, message: '' });
    setSMSNotificationError({ message: '' });
    setEmailTitle('');
    setEditorState(EditorState.createEmpty());
    setPushNotification({ title: '', message: '' });
    setMessage('');
    setBlockedSucessfully(false);
  };

  const deleteChannelService = async () => {
    const data = await ChannelInfoService.deleteChannelApi(id);
    if (data.data.statusCode === 202) {
      history.push('/channelmanagement');
    }
  };
  const blockChannelService = async () => {
    await ChannelInfoService.blockChannelApi(id, channelInfo.data.status === 'active' ? 'inactive' : 'active');
    handleModalClose();
    setBlockedSucessfully(true);
    setShowModal(true);
  };
  return (
    <>
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className={scss.modal_container}>
          {showDelete && (
            <div className={scss.delete_modalContainer}>
              <img src={warning} alt="warning" />
              <h2>Delete Channel? </h2>
              <p>Are you sure you want to delete this Channel? </p>
              <div className={scss.modal_footer}>
                <p
                  role="presentation"
                  onClick={handleModalClose}
                >
                  No
                </p>
                <p
                  role="presentation"
                  onClick={deleteChannelService}
                >
                  Yes
                </p>
              </div>
            </div>
          )}
          {showBlock && (
            <div className={scss.delete_modalContainer}>
              <img src={warning} alt="warning" />
              <h2>
                {channelInfo.data.status === 'active' ? 'Block Channel?' : 'Unblock Channel?'}
                {' '}
              </h2>
              <p>
                Are you sure you want to
                {' '}
                {channelInfo.data.status === 'active' ? 'block' : 'unblock'}
                {' '}
                this Channel?
                {' '}
              </p>
              <div className={scss.modal_footer}>
                <p
                  role="presentation"
                  onClick={handleModalClose}
                >
                  No
                </p>
                <p
                  role="presentation"
                  onClick={blockChannelService}
                >
                  Yes
                </p>
              </div>
            </div>
          )}
        </div>
        {
          sendNotification && (
            <div className={scss.notication_container}>
              <p
                role="presentation"
                className={scss.buttoNot}
                onClick={() => {
                  setShowEmailNotification(true);
                  setSendNotification(false);
                }}
              >
                Email Notification
              </p>
              <p
                role="presentation"
                className={scss.buttoNot}
                onClick={() => {
                  setShowSMSNotification(true);
                  setSendNotification(false);
                }}
              >
                SMS Notification
              </p>
              <p
                role="presentation"
                className={scss.buttoNot}
                onClick={() => {
                  setShowPushNotification(true);
                  setSendNotification(false);
                }}
              >
                Push Notification
              </p>
            </div>
          )
        }
        {
          showEmailNotification && (
            <div>
              <h2>Email Notification</h2>
              <TextField
                onChange={(e) => {
                  setEmailTitle(e.target.value);
                }}
                id="emailTitle"
                label="*Subject of the Email"
                name="textField"
                placeholder="Email Title"
                variant="outlined"
                style={{ width: '100%' }}
              />
              {emailNotificationError.title && <ValidationError error="Email title can not be empty" />}
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => setEditorState(e)}
                toolbar={{
                  options: ['inline', 'textAlign'],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['bold', 'italic', 'underline'],
                  },
                  textAlign: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['left', 'center', 'right', 'justify'],
                  },
                }}
              />
              {emailNotificationError.message === 'empty' && <ValidationError error="Email content can not be empty" />}
              {emailNotificationError.message === 'greater' && <ValidationError error="Email content can not be greater than 2000 alphabets" />}

              <div className={scss.modal_footer}>
                <Button
                  className={scss.margin_right}
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
                <Button
                  className={scss.margin_right}
                  onClick={() => {
                    if (emailTitle.length === 0) {
                      setEmailNotificationError({ ...emailNotificationError, title: true });
                    } else if (editorState.getCurrentContent().getPlainText().length === 0) {
                      setEmailNotificationError({ ...emailNotificationError, title: false, message: 'empty' });
                    } else if (editorState.getCurrentContent().getPlainText().length > 2000) {
                      setEmailNotificationError({ ...emailNotificationError, message: 'greater' });
                    } else {
                      ChannelInfoService.sendEmail({
                        channelId: id,
                        subject: emailTitle,
                        message: stateToHTML(editorState.getCurrentContent()),
                        // eslint-disable-next-line no-underscore-dangle
                        userId: channelInfo.data.userId._id,
                      }).then(() => {
                        handleModalClose();
                      });
                    }
                  }}
                >
                  Send Notification
                </Button>
              </div>
            </div>
          )
        }
        {
          showPushNotification && (
            <div>
              <h2>Push Notification</h2>
              <TextField
                onChange={(e) => {
                  setPushNotification({
                    ...pushNotification,
                    title: e.target.value,
                  });
                }}
                id="emailTitle"
                label="*Subject of the Notification"
                name="textField"
                placeholder="Email Title"
                variant="outlined"
                style={{ width: '100%' }}
              />
              {pushNotificationError.title
                && <ValidationError error="Notification title cannot be empty" />}
              <textarea
                placeholder="Enter message"
                onChange={(e) => {
                  setPushNotification({
                    ...pushNotification,
                    message: e.target.value,
                  });
                }}
              />
              {pushNotificationError.message === 'empty'
                && <ValidationError error="Notification message cannot be empty" />}
              {pushNotificationError.message === 'greater' && <ValidationError error="Notification message cannot be greater than 300 alphabets" />}

              <div className={scss.modal_footer}>
                <Button
                  className={scss.margin_right}
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
                <Button
                  className={scss.margin_right}
                  onClick={() => {
                    if (pushNotification.title === '') {
                      setPushNotificationError({ ...pushNotificationError, title: true });
                    } else if (pushNotification.message === '') {
                      setPushNotificationError({ ...pushNotificationError, title: false, message: 'empty' });
                    } else if (pushNotification.message.length > 300) {
                      setPushNotificationError({ ...pushNotificationError, message: 'greater' });
                    } else {
                      ChannelInfoService.sendPushNotification({
                        channelId: id,
                        title: pushNotification.title,
                        message: pushNotification.message,
                        // eslint-disable-next-line no-underscore-dangle
                        userId: channelInfo.data.userId._id,
                      }).then(() => {
                        handleModalClose();
                      });
                    }
                  }}
                >
                  Send Notification
                </Button>
              </div>
            </div>
          )
        }
        {
          showSMSNotification && (
            <div>
              <h2>Send Notification</h2>
              <textarea
                placeholder="Enter Message"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              {smsNotificationError.message === 'empty'
                && <ValidationError error="SMS Message cannot be empty" />}
              {smsNotificationError.message === 'greater'
                && <ValidationError error="SMS cannot be greater than 300 alphabets" />}

              <div className={scss.modal_footer}>
                <Button
                  className={scss.margin_right}
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
                <Button
                  className={scss.margin_right}
                  onClick={() => {
                    if (message.length === 0) {
                      setSMSNotificationError({ ...smsNotificationError, message: 'empty' });
                    } else if (message.length > 300) {
                      setSMSNotificationError({ ...smsNotificationError, message: 'greater' });
                    } else {
                      ChannelInfoService.sendSMS({
                        channelId: id,
                        message,
                        // eslint-disable-next-line no-underscore-dangle
                        userId: channelInfo.data.userId._id,
                      }).then(() => {
                        handleModalClose();
                      });
                    }
                  }}
                >
                  Send Notification
                </Button>
              </div>
            </div>
          )
        }
        {
          blockedSucessfully && (
            <div>
              <h3>
                Channel
                {' '}
                {channelInfo.data.status === 'active' ? 'blocked' : 'unblocked'}
                {' '}
                sucessfully
              </h3>
              <Button onClick={() => {
                fetchChannelInfo(id);
                handleModalClose();
              }}
              >
                Ok
              </Button>
            </div>
          )
        }
      </Modal>
      {
        channelInfo.data === null || channelInfo.loadingChannelInfoProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className={classNames(commonStyles.cardBg, scss.container)}>
                <div className={scss.headerActions}>
                  <div>
                    <Button
                      className={scss.button}
                      onClick={() => {
                        setShowModal(true);
                        setSendNotification(true);
                      }}
                    >
                      Send Notification
                    </Button>
                  </div>
                  <div className={scss.iconWrapperbox}>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.DELETE}
                    >
                      <span className={scss.iconWrapper}>
                        <img
                          src={deleteImg}
                          alt="deleteImg"
                          role="presentation"
                          title="Delete"
                          onClick={() => {
                            setShowModal(true);
                            setShowDelete(true);
                          }}
                        />
                      </span>
                    </AccessControl>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className={scss.iconWrapper}>
                        <img
                          src={exportImg}
                          alt="exportImg"
                          title="Export"
                        />
                      </span>
                    </AccessControl>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      {channelInfo.data.status === 'active' ? (
                        <span className={scss.iconWrapper}>
                          <img
                            src={blockImg}
                            alt="blockImg"
                            title="Block"
                            role="presentation"
                            onClick={() => {
                              setShowModal(true);
                              setShowBlock(true);
                            }}
                          />
                        </span>
                      ) : (
                        <span className={scss.iconWrapper}>
                          <img
                            src={unblockImg}
                            alt="unblockImg"
                            title="unblock"
                            role="presentation"
                            onClick={() => {
                              setShowModal(true);
                              setShowBlock(true);
                            }}
                          />
                        </span>
                      )}
                    </AccessControl>
                  </div>
                </div>
                <div className={scss.userInformatinc}>
                  <div>
                    {channelInfo.data.channelIconImage !== ''
                      && channelInfo.data.channelIconImage !== undefined
                      ? (
                        <img
                          src={channelInfo.data.channelIconImage}
                          alt="profileImg"
                        />
                      )
                      : <img src={profileImg} alt="profile" />}
                  </div>
                  <div className={scss.userInfoinner}>
                    <div>
                      <h2>{channelInfo.data.channelName}</h2>
                      <h3>Description</h3>
                      <p>
                        {channelInfo.data.description}
                      </p>
                    </div>
                    <div className={scss.right_container}>
                      <h3>
                        Created On:
                        {' '}
                        <span>
                          {dateFormatting(channelInfo.data.createdAt)}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className={scss.postDetails}>
                  <div>
                    <div>
                      <p className={scss.heading_styles}>
                        User
                      </p>
                      <p className={scss.count_backround}>
                        {channelInfo.data.userId?.username}
                      </p>
                    </div>
                    <div>
                      <p className={scss.heading_styles}>
                        Link to the Website
                      </p>
                      <p className={scss.count_backround}>
                        {channelInfo.data.link}
                      </p>
                    </div>
                    <div>
                      <p className={scss.heading_styles}>Dome</p>
                      <p className={scss.count_backround}>
                        {channelInfo.data.categoryId?.name}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className={scss.heading_styles}>
                        Pricing Plan
                      </p>
                      <p className={scss.count_backround}>
                        {channelInfo.data.subscriptionType}
                      </p>
                    </div>
                    <div>
                      <p className={scss.heading_styles}>
                        Subscription Amount
                      </p>
                      <p className={scss.count_backround}>
                        0
                      </p>
                    </div>
                    <div>
                      <p className={scss.heading_styles}>
                        No. of Subscribers
                      </p>
                      <p className={scss.count_backround}>
                        {channelInfo.data.subscriberCount}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={scss.rewardTable}>
                <PostCreated id={id} />
              </div>
              <div className={scss.rewardTable}>
                <ChannelReportDetails id={id} />
              </div>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { channelinfo } = state;
  return {
    channelInfo: channelinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchChannelInfo: (payload: any) => dispatch(getChannelInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelInfo);
