import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { injectIntl } from 'react-intl';
import {
  FieldTextInput, Button, ValidationError,
} from '../../components';
import scss from './resetpassword.module.scss';

interface Props {
  handleFormSubmit: any
  intl: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const forgotPassSchema = yup.object().shape({
  password: yup.string()
    .required('Password can not be empty')
    .matches(/((?=.*\d)(?=.*[A-Z])(?=.*\W).{8})/, `Please enter minimum 8 character password with
    atleast 1 upper case, 1 lower case & 1 special character.`)
    .max(30, 'Password should be less then 30 characters'),
}, []);

const ResetPasswordForm: React.FC<Props> = (props: Props) => {
  const { handleFormSubmit, intl } = props;
  const [passwordType, setPasswordType] = useState('password');
  const formProps = useForm({
    resolver: yupResolver(forgotPassSchema),
  });
  const passwordTypeHandler = () => {
    setPasswordType(passwordType === 'text' ? 'password' : 'text');
  };
  return (
    <form onSubmit={formProps.handleSubmit(handleFormSubmit)} className={scss.restForm}>
      <h2 className={scss.sign_text}>
        {intl.formatMessage({ id: 'ResetPassword.label.title' })}
      </h2>
      <p className={scss.details_text}>
        {intl.formatMessage({ id: 'ResetPassword.label.detail_text' })}
      </p>
      <FieldTextInput
        id="password"
        name="password"
        formName="password"
        className="password"
        type={passwordType}
        showVisibilityIcon
        passwordTypeHandler={passwordTypeHandler}
        formProps={formProps}
        label={intl.formatMessage({ id: 'ResetPassword.label.password' })}
      />
      {Object.keys(formProps.formState.errors).length > 0
        && (
          <ValidationError
            className={scss.validation_error}
            error={formProps.formState.errors.password.message}
          />
        )}
      <Button buttonType={ButtonTypes.submit}>
        {intl.formatMessage({ id: 'ResetPassword.label.setNewPassword' })}
      </Button>
    </form>
  );
};

export default injectIntl(ResetPasswordForm);
