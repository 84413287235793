import { all, fork } from 'redux-saga/effects';
import PostCreated from './postcreated';
import auth from './auth';
import UserManagement from './usermanagement';
import UserInfo from './userinfo';
import ChannelManagement from './channelmanagement';
import ChannelInfo from './channelInfo';
import UserProfile from './userProfile';
import Domesmanagement from './domesmanagement';
import SubDomesmanagement from './submanagement';
import ContentManagement from './contentmanagement';
import FAQs from './faqs';
import RolesManagement from './rolesmanagement';
import LoginDetails from './logindetails';
import SubAdminManagement from './subadminmanagement';
import CreditActivity from './creditactivity';
import DebitActivity from './debitactivity';
import ReportedUserManagement from './reportedusermanagement';
import NotesDetails from './notesdetails';
import UserReportDetail from './userreportdetail';
import PostReportDetail from './postreportdetail';
import ChannelReportDetail from './channelreportdetail';
import NotificationDetail from './notificationdetail';
import PriceManagementDetail from './pricemanagement';
/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(auth),
    fork(UserManagement),
    fork(UserInfo),
    fork(ChannelManagement),
    fork(ChannelInfo),
    fork(UserProfile),
    fork(Domesmanagement),
    fork(SubDomesmanagement),
    fork(ContentManagement),
    fork(FAQs),
    fork(RolesManagement),
    fork(PostCreated),
    fork(LoginDetails),
    fork(SubAdminManagement),
    fork(CreditActivity),
    fork(DebitActivity),
    fork(ReportedUserManagement),
    fork(NotesDetails),
    fork(UserReportDetail),
    fork(PostReportDetail),
    fork(ChannelReportDetail),
    fork(NotificationDetail),
    fork(PriceManagementDetail),
  ]);
}
