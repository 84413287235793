import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { injectIntl } from 'react-intl';
import {
  FieldTextInput, Button, FieldCheckbox, ValidationError,
} from '../../components';
import scss from './loginform.module.scss';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  intl: any;
  handleFormSubmit: any;
  loginError: any;
  changeSuccessPass: any;
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const loginSchema = yup.object().shape({
  email: yup.string()
    .required('Please enter the required fields')
    .matches(/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$/, 'Please enter a valid email address'),
  password: yup.string().required('Please enter the requried fields'),
}, []);

const LoginForm: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit, loginError, changeSuccessPass, intl,
  } = props;
  const [passwordType, setPasswordType] = useState('password');
  const formProps = useForm({
    defaultValues: {
      email: Cookies.get('email_authInfo') !== undefined ? Cookies.get('email_authInfo') : '',
      password: Cookies.get('password_authInfo') !== undefined ? Cookies.get('password_authInfo') : '',
    },
    resolver: yupResolver(loginSchema),
  });
  const history = useHistory();
  const passwordTypeHandler = () => {
    setPasswordType(passwordType === 'text' ? 'password' : 'text');
  };
  return (
    <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <h2 className={scss.sign_text}>
        {intl.formatMessage({ id: 'LoginForm.page.title' })}
      </h2>
      <p className={scss.details_text}>
        {intl.formatMessage({ id: 'LoginForm.label.details' })}
      </p>
      <FieldTextInput
        id="email"
        name="email"
        formName="login"
        className="textInput"
        type="text"
        formProps={formProps}
        label={intl.formatMessage({ id: 'LoginForm.label.email' })}
      />
      <FieldTextInput
        id="password"
        name="password"
        formName="signup"
        className="textInput"
        type={passwordType}
        passwordTypeHandler={passwordTypeHandler}
        formProps={formProps}
        showVisibilityIcon
        rootClassName={scss.passwordInput}
        label={intl.formatMessage({ id: 'LoginForm.label.password' })}
      />
      <div className={scss.rememberMeWrapper}>

        <div className={scss.remember}>
          <FieldCheckbox
            id="remember"
            name="remember"
            formProps={formProps}
            disableRipple
          />
          Remember me
        </div>

        <small
          role="presentation"
          className={scss.forgotpassword_text}
          onClick={() => {
            changeSuccessPass();
            history.push('/forgotpassword');
          }}
        >
          Forgot Password?
        </small>
      </div>
      {Object.keys(formProps.formState.errors).length > 0
        && (
          <ValidationError
            className={scss.validation_error}
            error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
          />
        )}
      {Object.keys(formProps.formState.errors).length === 0 && loginError && (
        <ValidationError
          className={scss.validation_error}
          error={loginError}
        />
      )}
      <Button buttonType={ButtonTypes.submit}>Login</Button>
    </form>
  );
};

export default injectIntl(LoginForm);
