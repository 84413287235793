import api from '../api';

export class DomesManagementService {
  static async getDomesList(payload: any) {
    const {
      page,
      rowsPerPage,
      status,
      search,
      isSortByCreated,
      isSortByCategoryName,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByCategoryName !== undefined && isSortByCategoryName !== '' ? `&isSortByCategoryName=${isSortByCategoryName}` : ''}`;
    return api.getApiCall(`/api/v1/admins/categories?${apiEndPoint}`);
  }

  static async getAllDomesList() {
    return api.getApiCall('/api/v1/admins/categories/names');
  }

  static async editAllDomes(payload: any) {
    return api.putApiCall('/api/v1/admins/categories/ranks', { categories: payload });
  }

  static async deleteDomes(payload: any) {
    return api.deleteApiCall('/api/v1/admins/categories', {
      categoryId: payload,
    });
  }

  static async blockDomes(payload: any) {
    return api.putApiCall('/api/v1/admins/categories/activate-deactivate', {
      categoryId: payload.id,
      status: payload.status,
    });
  }

  static async uploadpicture(payload: any) {
    return api.postApiCall('/api/v1/admins/upload-files', payload);
  }

  static async addDomes(payload: any) {
    return api.postApiCall('/api/v1/admins/categories', { ...payload, status: 'unblocked' });
  }

  static async saveDomesToDraft(payload: any) {
    return api.postApiCall('/api/v1/admins/categories', { ...payload, status: 'pending' });
  }

  static async savePutDomesToDraft(payload: any) {
    return api.putApiCall('/api/v1/admins/categories', { ...payload, status: 'pending' });
  }

  static async getDetails(payload: any) {
    return api.getApiCall(`/api/v1/admins/categories/${payload}`, payload);
  }

  static async editDetails(payload: any) {
    return api.putApiCall('/api/v1/admins/categories', {
      categoryId: payload.categoryId,
      name: payload.name,
      description: payload.description,
      selectedImage: payload.selectedImage,
      nonSelectedImage: payload.nonSelectedImage,
      status: payload.status,
    });
  }

  static async editPutDetails(payload: any) {
    return api.putApiCall('/api/v1/admins/categories', {
      categoryId: payload.categoryId,
      name: payload.name,
      description: payload.description,
      selectedImage: payload.selectedImage,
      nonSelectedImage: payload.nonSelectedImage,
      status: payload.status,
    });
  }

  static async exportUserListToMail(payload: any) {
    const {
      status,
      search,
      isSortByCreated,
      isSortByCategoryName,
      type,
    } = payload;
    const apiEndPoint = `${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByCategoryName !== undefined && isSortByCategoryName !== '' ? `&isSortByCategoryName=${isSortByCategoryName}` : ''}&type=${type}`;
    return api.getApiCall(`/api/v1/admins/categories/exports?${apiEndPoint}`);
  }
}
