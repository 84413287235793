import { getNotesDetails } from 'actions';
import {
  DataTable,
} from 'components';
import React from 'react';
import { connect } from 'react-redux';
import { dateFormatting } from 'utils/dateMethods';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  notesdetails: any;
  fetchNotesDetails: any;
  id: any;
}

const NotestDetail = (props: Props) => {
  const { fetchNotesDetails, notesdetails, id } = props;
  const [selected, setSelected] = React.useState([]);
  const [filter, setFilter] = React.useState({
    id,
    page: 0,
    rowsPerPage: 10,
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  React.useEffect(() => {
    fetchNotesDetails(filter);
  }, [fetchNotesDetails, filter]);

  const rows: any = [];

  function createData(data: any, index: number): any {
    // eslint-disable-next-line no-underscore-dangle
    const id_ = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      description: data.description,
      addedby: data.admin.name,
      createdOn: dateFormatting(data.createdAt),
    };
  }
  if (notesdetails.data !== null && notesdetails.data) {
    notesdetails.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headerCellsUserReports: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'description', label: 'Notes Description',
    },
    {
      id: 'addedby', label: 'Added By',
    },
    {
      id: 'createdOn', label: 'Added on',
    },
  ];
  const count = notesdetails.data ? notesdetails.data['Pagination-Count'] : 0;

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  return (
    <>
      <h2>Notes Detail</h2>
      <div className={commonStyles.dataTableCover}>
        <DataTable
          headCells_={headerCellsUserReports}
          rows_={rows}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={notesdetails.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { notesdetails } = state;
  return {
    notesdetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchNotesDetails: (payload: any) => dispatch(getNotesDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotestDetail);
