import React from 'react';
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

interface Props {
  location: any;
  history: any;
}

const Breadcrumbs = (props: Props) => {
  const {
    history,
    location: { pathname },
  } = props;

  const pathKeys = {
    usermanagement: 'User Management',
    userdetails: 'User Details',
    channelmanagement: 'Channel Management',
    channeldetails: 'Channel Details',
    rolemanagement: 'Role Management',
    addRoles: 'Add New Roles',
    editRoles: 'Edit Roles',
    domesmanagement: 'Domes Management',
    addDomes: 'Add Domes',
    editDomes: 'Edit Domes',
    subdomesmanagement: 'Sub Domes Management',
    addSubDomes: 'Add Sub Domes',
    editSubDomes: 'Edit Sub Domes',
    subadminmanagement: 'SubAdmin Management',
    addSubAdmin: 'Add Sub Admin',
    editSubAdmin: 'Edit Sub Admin',
    reportusermanagement: 'Report User Management',
    reportuserinfo: 'User Report Info',
  };

  const pathnames: any = [];
  pathname.split('/').forEach((x: any) => {
    Object.keys(pathKeys).forEach((ele: any) => {
      if (ele === x) {
        pathnames.push({ key: pathKeys[ele], value: ele });
      }
    });
  });

  return (
    <MUIBreadcrumbs separator=">" aria-label="breadcrumb">
      {pathnames.length > 1 && pathnames.map((name: any, index: any) => {
        const routeTo = `/${name.value}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography key={name.key}>{name.key}</Typography>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link key={name} onClick={() => history.push(routeTo)}>
            {name.key}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);
