import { Button } from 'components';
import React from 'react';
import { ContentManagementService } from 'services';
import warning from '../../assets/svg/warning.svg';
import scss from './deletefaqs.module.scss';

interface Props{
    handleModalClose: any;
    id: any
}
enum ButtonTypes {
    'button',
    'submit',
    'reset',
    undefined
}

const DeleteFAQS: React.FC<Props> = (props: Props) => {
  const { handleModalClose, id } = props;

  const deleteUserService = async (e: any) => {
    e.preventDefault();
    const data = await ContentManagementService.deleteFAQ({ faqId: id });
    if (data.data.statusCode === 202) {
      handleModalClose();
    }
  };
  return (
    <>
      <img src={warning} alt="warning" />
      <h2>Delete FAQs? </h2>
      <p>Are you sure you want to delete this FAQ? </p>
      <div className={scss.modal_footer}>
        <Button buttonType={ButtonTypes.button} onClick={handleModalClose}>No</Button>
        <Button buttonType={ButtonTypes.button} onClick={deleteUserService}>Yes</Button>
      </div>
    </>
  );
};
export default DeleteFAQS;
