import { PERMISSION_KEYS, PERMISSION_TYPES, hasAccess } from 'components';
import {
  ReportUserInfo,
  NotFoundPage,
  AuthenticationPage,
  DashboardPage,
  UserManagementComponentPage,
  UserInfo,
  ChannelManagementPage,
  ChannelInfo,
  MyProfileComponent,
  DomesManagementComponentPage,
  ManageDomes,
  SubDomesManagement,
  ManageSubDomes,
  ContentManagement,
  ManageFAQs,
  RoleManagement,
  ManageRoles,
  SubAdminManagement,
  ManageSubAdminComponent,
  RewardPointManagement,
  ReportUserManagement,
  SettingComponent,
} from '../modules';
// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
// import { NamedRedirect } from './components';

// const pageDataLoadingAPI = getPageDataLoadingAPI();
// const draftId = '00000000-0000-0000-0000-000000000000';
// const draftSlug = 'draft';

const routeConfiguration = () => [
  {
    path: '/login',
    name: 'LoginPage',
    component: AuthenticationPage,
    extraProps: { tab: 'login', access: true },
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: AuthenticationPage,
    extraProps: { tab: 'forgotpassword', access: true },
  },
  {
    path: '/myProfile',
    name: 'MyProfile',
    component: MyProfileComponent,
    extraProps: { tab: 'myprofile', access: true },
    auth: true,
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: AuthenticationPage,
    extraProps: { tab: 'resetPassword', access: true },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardPage,
    extraProps: { tab: 'dashboard', access: true },
    auth: true,
  },
  {
    path: '/usermanagement',
    name: 'UserManagement',
    component: UserManagementComponentPage,
    extraProps: {
      tab: 'usermanagement',
      access: hasAccess(
        PERMISSION_KEYS.USER_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    auth: true,
  },
  {
    path: '/usermanagement/userdetails/:id',
    name: 'User Info',
    component: UserInfo,
    extraProps: {
      tab: 'userinfo',
      access: hasAccess(
        PERMISSION_KEYS.USER_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    auth: true,
  },
  {
    path: '/channelmanagement',
    name: 'ChannelManagement',
    component: ChannelManagementPage,
    extraProps: {
      tab: 'channelmanagement',
      access: hasAccess(
        PERMISSION_KEYS.CHANNEL_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    auth: true,
  },
  {
    path: '/channelmanagement/channeldetails/:id',
    name: 'Channel Details',
    component: ChannelInfo,
    extraProps: {
      tab: 'channeldetails',
      access: hasAccess(
        PERMISSION_KEYS.CHANNEL_MANAGEMENT,
        PERMISSION_TYPES.VIEW,
      ),
    },
    auth: true,
  },
  {
    path: '/domesmanagement',
    name: 'DomesManagement',
    component: DomesManagementComponentPage,
    extraProps: {
      tab: 'domesmanagement',
      access: hasAccess(
        PERMISSION_KEYS.CATERGORIES,
        PERMISSION_TYPES.VIEW,
      ),
    },
    auth: true,
  },
  {
    path: '/domesmanagement/addDomes',
    name: 'AddDomes',
    component: ManageDomes,
    extraProps: {
      tab: 'adddomes',
      access: hasAccess(
        PERMISSION_KEYS.CATERGORIES,
        PERMISSION_TYPES.ADD,
      ),
    },
    auth: true,
  },
  {
    path: '/domesmanagement/editDomes/:id',
    name: 'EditDomes',
    component: ManageDomes,
    extraProps: {
      tab: 'editdomes',
      access: hasAccess(
        PERMISSION_KEYS.CATERGORIES,
        PERMISSION_TYPES.EDIT,
      ),
    },
    auth: true,
  },
  {
    path: '/subdomesmanagement',
    name: 'SubDomesManagement',
    component: SubDomesManagement,
    extraProps: {
      tab: 'subdomesmanagement',
      access: hasAccess(
        PERMISSION_KEYS.SUB_CATEGORIES,
        PERMISSION_TYPES.VIEW,
      ),
    },
    auth: true,
  },
  {
    path: '/subdomesmanagement/addSubDomes',
    name: 'AddSubDomes',
    component: ManageSubDomes,
    extraProps: {
      tab: 'addsubdomes',
      access: hasAccess(PERMISSION_KEYS.SUB_CATEGORIES, PERMISSION_TYPES.ADD),
    },
    auth: true,
  },
  {
    path: '/subdomesmanagement/editSubDomes/:id',
    name: 'EditSubDomes',
    component: ManageSubDomes,
    extraProps: {
      tab: 'editsubdomes',
      access: hasAccess(PERMISSION_KEYS.SUB_CATEGORIES, PERMISSION_TYPES.EDIT),
    },
    auth: true,
  },
  {
    path: '/contentmanagement',
    name: 'ContentManagement',
    component: ContentManagement,
    extraProps: {
      tab: 'contentmanagement',
      access: true,
    },
    auth: true,
  },
  {
    path: '/contentmanagement/:id',
    name: 'ManageFAQs',
    component: ManageFAQs,
    extraProps: { tab: 'managefaqs', access: true },
    auth: true,
  },
  {
    path: '/rolemanagement',
    name: 'RoleManagement',
    component: RoleManagement,
    extraProps: {
      tab: 'rolemanagement',
      access: hasAccess(PERMISSION_KEYS.ROLE_MANAGEMENT, PERMISSION_TYPES.VIEW),
    },
    auth: true,
  },
  {
    path: '/rolemanagement/addRoles',
    name: 'AddRoles',
    component: ManageRoles,
    extraProps: {
      tab: 'addroles',
      access: hasAccess(PERMISSION_KEYS.ROLE_MANAGEMENT, PERMISSION_TYPES.ADD),
    },
    auth: true,
  },
  {
    path: '/rolemanagement/editRoles/:id',
    name: 'EditRoles',
    component: ManageRoles,
    extraProps: {
      tab: 'editroles',
      access: hasAccess(PERMISSION_KEYS.ROLE_MANAGEMENT, PERMISSION_TYPES.EDIT),
    },
    auth: true,
  },
  {
    path: '/subadminmanagement',
    name: 'SubAdminManagement',
    component: SubAdminManagement,
    extraProps: {
      tab: 'subadminmanagement',
      access: hasAccess(PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT, PERMISSION_TYPES.VIEW),
    },
    auth: true,
  },
  {
    path: '/rewardpoints',
    name: 'RewardPointManagement',
    component: RewardPointManagement,
    extraProps: {
      tab: 'rewardpoints',
      access: hasAccess(PERMISSION_KEYS.REWARD_POINTS, PERMISSION_TYPES.VIEW),
    },
    auth: true,
  },
  {
    path: '/subadminmanagement/addSubAdmin',
    name: 'AddSubAdmin',
    component: ManageSubAdminComponent,
    extraProps: {
      tab: 'addsubadmin',
      access: hasAccess(PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT, PERMISSION_TYPES.ADD),
    },
    auth: true,
  },
  {
    path: '/subadminmanagement/editSubAdmin/:id',
    name: 'EditSubAdmin',
    component: ManageSubAdminComponent,
    extraProps: {
      tab: 'editsubadmin',
      access: hasAccess(PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT, PERMISSION_TYPES.EDIT),
    },
    auth: true,
  },
  {
    path: '/reportusermanagement',
    name: 'ReportUserManagement',
    component: ReportUserManagement,
    extraProps: {
      tab: 'reportusermanagement',
      access: hasAccess(PERMISSION_KEYS.REPORTED_POST_MANAGEMENT, PERMISSION_TYPES.VIEW),
    },
    auth: true,
  },
  {
    path: '/reportusermanagement/reportuserinfo/:id',
    name: 'ReportUserManagement',
    component: ReportUserInfo,
    extraProps: {
      tab: 'reportusermanagement',
      access: hasAccess(PERMISSION_KEYS.REPORTED_POST_MANAGEMENT, PERMISSION_TYPES.EDIT),
    },
    auth: true,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingComponent,
    extraProps: {
      tab: 'settings',
      access: hasAccess(PERMISSION_KEYS.COMMISION_MANAGEMENT, PERMISSION_TYPES.VIEW),
    },
    auth: true,
  },
  {
    path: '/notfound',
    name: 'NotFoundPage',
    component: NotFoundPage,
    extraProps: { tab: 'notfound', access: true },
  },
];

export default routeConfiguration;
