import { Button } from 'components';
import React from 'react';
import { RolesManagementService } from 'services';
import warning from '../../assets/svg/warning.svg';
import scss from './rolemanagementblocked.module.scss';

interface Props {
  handleModalClose: any;
  id: any;
  fetchDomesList: any;
  status: any;
}
const RoleManagementBlocked: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    fetchDomesList,
    status,
  } = props;

  const [blocked, setBlocked] = React.useState(false);
  const blockDomesService = () => {
    RolesManagementService.blockedRole({ roleId: id, status }).then(() => {
      setBlocked(true);
    });
  };

  return (
    <>
      {!blocked ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            {status === 'blocked' ? 'Block' : 'Unblock'}
            {' '}
            Roles
          </h2>
          <p>
            Are you sure you want to
            {' '}
            {status === 'blocked' ? 'block' : 'unblock'}
            {' '}
            this Role?
          </p>
          <div className={scss.modal_footer}>
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={blockDomesService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Role
            {' '}
            {status === 'blocked' ? 'blocked' : 'unblocked'}
            {' '}
            successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchDomesList({
              page: 1,
              rowsPerPage: 10,
            });
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default RoleManagementBlocked;
