import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from '../../utils/reactIntl';
// import config from '../../config.js';
import scss from './Footer.module.scss';

const Footer = (props: any) => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || scss.root, className);

  return (
    <div className={classes}>
      <div className={scss.topBorderWrapper}>
        <div className={scss.content}>
          Footer
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  // intl: intlShape.isRequired,
};

export default injectIntl(Footer);
