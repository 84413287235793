/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
// import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { injectIntl } from '../../utils/reactIntl';
// import routeConfiguration from '../../routes/routeConfiguration';
// import { createResourceLocatorString } from '../../utils/routes';
// import {
//   Page,
//   LayoutSingleColumn,
//   LayoutWrapperTopbar,
//   LayoutWrapperMain,
//   LayoutWrapperFooter,
//   Footer,
// } from '../../components';
// import { TopbarContainer } from '../../containers';
interface staticcontext {
    notfound: boolean
}

interface Props {
    scrollingDisabled?: boolean,

    staticContext: staticcontext,

    // from injectIntl
    intl: any,

    // from withRouter
    history: any,
    match : any,
    location: any,
}

const NotFoundPageComponent: React.FC<Props & RouteComponentProps<any>> = (props) => {
  // The StaticRouter component used in server side rendering
  // provides the context object. We attach a `notfound` flag to
  // the context to tell the server to change the response status
  // code into a 404.
  if (props.staticContext) { props.staticContext.notfound = true; }

  return (

    <div>
      <h3>404 Not Found</h3>
    </div>
  );
};

const mapStateToProps = () => ({
});

const NotFoundPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl,
)(NotFoundPageComponent);

export default NotFoundPage;
