import routing from './routing';
import auth from './auth';
import usermanagement from './usermanagement';
import userinfo from './userinfo';
import channelmanagement from './channelmanagement';
import userProfile from './userProfile';
import domes from './domesmanagement';
import subdomesmanagement from './subdomesmanagement';
import contentmanagement from './contentmanagement';
import faqs from './faqs';
import rolesmanagement from './rolesmanagement';
import postcreated from './postcreated';
import logindetails from './logindetails';
import subadminmanagement from './subadminmanagement';
import creditactivitydetails from './creditactivitydetails';
import debitactivitydetails from './debitactivitydetails';
import reportedusermanagement from './reportedusermanagement';
import notesdetails from './notesdetails';
import userreportdetail from './userreportdetail';
import postreportdetail from './postreportdetail';
import channelreportdetail from './channelreportdetail';
import notificationdetails from './notificationdetails';
import pricemanagement from './pricemanagement';

export const ActionTypes = {
  SWITCH_MENU: 'APP/Alerts/SWITCH_MENU',
  EXCEPTION: 'APP/Alerts/EXCEPTION',

  SHOW_ALERT: 'APP/Alerts/SHOW_ALERT',
  HIDE_ALERT: 'APP/Alerts/HIDE_ALERT',
  ...routing,
  ...auth,
  ...usermanagement,
  ...userinfo,
  ...channelmanagement,
  ...userProfile,
  ...domes,
  ...subdomesmanagement,
  ...contentmanagement,
  ...faqs,
  ...rolesmanagement,
  ...postcreated,
  ...logindetails,
  ...subadminmanagement,
  ...creditactivitydetails,
  ...debitactivitydetails,
  ...reportedusermanagement,
  ...notesdetails,
  ...userreportdetail,
  ...postreportdetail,
  ...channelreportdetail,
  ...notificationdetails,
  ...pricemanagement,
};
