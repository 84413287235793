import React from 'react';
import ReactDOM from 'react-dom';

import { loadableReady } from '@loadable/component';
import { PersistGate } from 'redux-persist/integration/react';
import { authInfo, showAlert } from './actions';

import { store, persistor } from './store/index';
import { AdminApp } from './App';
import reportWebVitals from './reportWebVitals';

import { Reload } from './components';

import { register } from './serviceWorker.js';

import './assets/styles/_avatusDefault.scss';

const render = (appStore: any, shouldHydrate: any) => {
  const { authInfoLoaded } = appStore.getState().auth;
  if (!authInfoLoaded) {
    authInfo(appStore.dispatch);
  }
  loadableReady()
    .then(() => {
      if (shouldHydrate) {
        ReactDOM.hydrate(<PersistGate persistor={persistor}><AdminApp appStore={appStore} /></PersistGate>, document.getElementById('root'));
      } else {
        ReactDOM.render(<PersistGate persistor={persistor}><AdminApp appStore={appStore} /></PersistGate>, document.getElementById('root'));
      }
    })
    .catch(() => {
      // to be handled
    });
};

declare global {
  interface Window {
    __PRELOADED_STATE__:any;
  }
}

if (typeof window !== 'undefined') {
  // eslint-disable-next-line no-underscore-dangle
  render(store, !!window.__PRELOADED_STATE__);
}

reportWebVitals();

register({
  onUpdate: () => {
    store.dispatch(showAlert(<Reload />, { id: 'sw-update', icon: 'bolt', timeout: 0 }));
  },
});
