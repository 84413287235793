import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'utils/reactIntl';
import reminderemails from 'assets/svg/reminderemails.svg';
import contentmanagement from 'assets/svg/contentmanagement.svg';
import notificationmanagement from 'assets/svg/notificationmanagement.svg';
import support from 'assets/svg/support.svg';
import feedback from 'assets/svg/feedback.svg';
import reportmanagement from 'assets/svg/reportmanagement.svg';
import avatus from 'assets/images/avatus_logoo.svg';
import rolemanagement from 'assets/svg/rolemanagement.svg';
import subadmin from 'assets/svg/subadminmanagement.svg';
import { withViewport } from 'utils/contextHelpers.jsx';
import dashboard from 'assets/svg/dashboard.svg';
import usermanagement from 'assets/svg/usermanagement.svg';
import channelmanagement from 'assets/svg/channelmanagement.svg';
import subscription from 'assets/svg/subscription.svg';
import subscribedusers from 'assets/svg/subscribedusers.svg';
import service from 'assets/svg/service.svg';
import purchase from 'assets/svg/purchase.svg';
import campaign from 'assets/svg/campaign.svg';
import promotionsandadvertising from 'assets/svg/promotionsandadvertising.svg';
import sellerbrandmanagement from 'assets/svg/sellerbrandmanagement.svg';
import livestreaming from 'assets/svg/livestreaming.svg';
import postmanagement from 'assets/svg/postmanagement.svg';
import eventmanagement from 'assets/svg/eventmanagement.svg';
import payementmanagement from 'assets/svg/payementmanagement.svg';
import coinactivitymanagement from 'assets/svg/coinactivitymanagement.svg';
import commisionmanagement from 'assets/svg/commisionmanagement.svg';
import rewardmanagement from 'assets/svg/rewardmanagement.svg';
import categories from 'assets/svg/categories.svg';
import subcategories from 'assets/svg/subcategories.svg';
import issuesreported from 'assets/svg/issuesreported.svg';
import versionmanagement from 'assets/svg/versionmanagement.svg';
import reportedusermanagement from 'assets/svg/reportedusericon.svg';
import css from './sidebar.module.scss';

interface Props {
  icon: any;
  content: string;
  route: string;
}
interface SidebarProps {
  history: any,
}

const SidebarElements = (props: Props) => {
  const { icon, content, route } = props;
  return (
    <div className={css.sidebarelement}>
      <img src={icon} alt="img" />
      {route === '/usermanagement'
        || route === '/channelmanagement'
        || route === '/domesmanagement'
        || route === '/subdomesmanagement'
        || route === '/contentmanagement'
        || route === '/rolemanagement'
        || route === '/subadminmanagement'
        || route === '/rewardpoints'
        || route === '/reportusermanagement'
        ? (
          <Link to={route} className={css.p_styles}>
            {content}
          </Link>
        ) : (
          <Link to="/#" className={css.p_styles}>
            {content}
          </Link>
        )}
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const { history } = props;
  const sidebarContent = [
    { icon: dashboard, content: 'Dashboard', route: '/' },
    { icon: usermanagement, content: 'User Management', route: '/usermanagement' },
    { icon: channelmanagement, content: 'Channel Management', route: '/channelmanagement' },
    { icon: subscription, content: 'Subscription Plan Management', route: '/subscriptionplanmanagement' },
    { icon: subscribedusers, content: 'Subscribed Users', route: '/subscribedusers' },
    { icon: service, content: 'Service Provider Management', route: '/serviceprovidermanagement' },
    { icon: purchase, content: 'Purchase & Ecommerce', route: '/purchaseecommerce' },
    { icon: campaign, content: 'Campaign Management', route: '/campaignmanagement' },
    { icon: promotionsandadvertising, content: 'Promotion/Advertising', route: '/promotionadvertising' },
    { icon: sellerbrandmanagement, content: 'Seller/Brand Management', route: '/sellerbrandmanagement' },
    { icon: livestreaming, content: 'Live Streaming', route: '/livestreaming' },
    { icon: reportedusermanagement, content: 'Report User Management', route: '/reportusermanagement' },
    { icon: rolemanagement, content: 'Role Management', route: '/rolemanagement' },
    { icon: subadmin, content: 'Sub Admin Management', route: '/subadminmanagement' },
    { icon: postmanagement, content: 'Post Management', route: '/postmanagement' },
    { icon: eventmanagement, content: 'Event Management', route: '/eventmanagement' },
    { icon: payementmanagement, content: 'Payement Managament', route: '/paymentmanagement' },
    { icon: coinactivitymanagement, content: 'Coin Activity Management', route: '/coinactivitymanagement' },
    { icon: commisionmanagement, content: 'Commission Management', route: '/commisionmanagement' },
    { icon: rewardmanagement, content: 'Reward Points', route: '/rewardpoints' },
    { icon: categories, content: 'Domes Management', route: '/domesmanagement' },
    { icon: subcategories, content: 'Sub Domes Management', route: '/subdomesmanagement' },
    { icon: issuesreported, content: 'Issues Reported', route: '/issuesreported' },
    { icon: versionmanagement, content: 'Version Management', route: '/versionmanagement' },
    { icon: reminderemails, content: 'Reminder Emails', route: '/remideremails' },
    { icon: contentmanagement, content: 'Content Management', route: '/contentmanagement' },
    { icon: notificationmanagement, content: 'Notification Management', route: '/notificationmanagement' },
    { icon: support, content: 'Support', route: '/support' },
    { icon: feedback, content: 'Feedback', route: '/feedback' },
    { icon: reportmanagement, content: 'Report Management ', route: '/reportedmanagement' },
  ];

  return (
    <div className={css.sideBar}>
      <div>
        <SidebarElements
          icon={avatus}
          content=""
          route=""
        />
      </div>
      <ul>
        {sidebarContent.map((ele: any) => (
          <li
            key={ele.route}
            className={ele.route !== '/'
              ? (
                history.location.pathname.includes(ele.route) ? 'active-bar' : '')
              : history.location.pathname === '/' ? 'active-bar' : ''}
          >
            <SidebarElements
              icon={ele.icon}
              content={ele.content}
              route={ele.route}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
const mapStateToProps = () => ({
});

const SidebarComponent = compose(
  withViewport,
  injectIntl,
  connect(mapStateToProps, null),
  withRouter,
)(Sidebar);

export default SidebarComponent;
