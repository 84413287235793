import api from 'api';

export class RolesManagementService {
  static async getRolesManagement(payload: any) {
    const {
      page,
      rowsPerPage,
      startDate,
      endDate,
      userType,
      search,
      isSortByRole,
      isSortByCreated,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${userType !== undefined && userType !== '' ? `&status=${userType}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}${isSortByCreated !== '' && isSortByCreated !== undefined ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByRole !== '' && isSortByRole !== undefined ? `&isSortByRole=${isSortByRole}` : ''}`;
    return api.getApiCall(`/api/v1/admins/roles?${apiEndPoint}`);
  }

  static async getModulesList() {
    return api.getApiCall('/api/v1/admins/modules');
  }

  static async addRole(payload: any) {
    return api.postApiCall('api/v1/admins/roles', payload);
  }

  static async deleteRole(id: any) {
    return api.deleteApiCall('api/v1/admins/roles', { roleId: id });
  }

  static async blockedRole(payload: any) {
    return api.putApiCall('api/v1/admins/roles/activate-deactivate', payload);
  }

  static async getDetails(id: any) {
    return api.getApiCall(`api/v1/admins/roles/${id}`);
  }

  static async updateRoles(payload: any) {
    return api.putApiCall('api/v1/admins/roles', payload);
  }

  static async getAllRoles() {
    return api.getApiCall('/api/v1/admins/roles');
  }

  static async getRolesExport(payload: any) {
    const {
      page,
      rowsPerPage,
      type,
      startDate,
      endDate,
      userType,
      search,
      isSortByRole,
      isSortByCreated,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${userType !== undefined && userType !== '' ? `&status=${userType}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}${isSortByCreated !== '' && isSortByCreated !== undefined ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByRole !== '' && isSortByRole !== undefined ? `&isSortByRole=${isSortByRole}` : ''}&type=${type}`;
    return api.getApiCall(`/api/v1/admins/roles/exports?${apiEndPoint}`);
  }
}
