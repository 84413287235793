import { DataTable } from 'components';
import React from 'react';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

const EventReportDetail = () => {
  const [selected, setSelected] = React.useState([]);
  const headerCellsUserReports: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'reporter', label: 'Reporter Username',
    },
    {
      id: 'reason', label: 'Reason',
    },
    {
      id: 'createdOn', label: 'Report Date & Time',
    },
  ];
  return (
    <>
      <h2>Event Report Detail</h2>
      <div className={commonStyles.dataTableCover}>
        <DataTable
          headCells_={headerCellsUserReports}
          rows_={[]}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={0}
          rowsPerPage={10}
          loading={false}
          handleChangePage={() => null}
          handleSort={() => null}
          handleChangeRowsPerPage={() => null}
          count={0}
        />
      </div>
    </>
  );
};

export default EventReportDetail;
