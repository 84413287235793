import api from '../api';

export class ReportedUserManagementService {
  static async getReportedUserList(payload: any) {
    const {
      page,
      rowsPerPage,
      status,
      search,
      isSortByCreated,
      isSortByCategoryName,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByCategoryName !== undefined && isSortByCategoryName !== '' ? `&isSortByCategoryName=${isSortByCategoryName}` : ''}`;
    return api.getApiCall(`/api/v1/admins/reported-users/reported?${apiEndPoint}`);
  }

  static async exportListToMail(payload: any) {
    const {
      status,
      search,
      isSortByCreated,
      isSortByCategoryName,
    } = payload;
    const apiEndPoint = `${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByCategoryName !== undefined && isSortByCategoryName !== '' ? `&isSortByCategoryName=${isSortByCategoryName}` : ''}`;
    return api.getApiCall(`/api/v1/admins/reported-users/reported?${apiEndPoint}`);
  }
}
