import React, { useCallback, useEffect, useState } from 'react';
import { DataTable } from 'components';
import { getDateAndTime } from 'utils/dateMethods';
import { ChannelInfoService } from 'services';
import scss from './channelreport.module.scss';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  id: string;
}

const ChannelReportDetails: React.FC<Props> = (props: Props) => {
  const { id } = props;
  const [channelReportPageNumber, setChannelReportPageNumber] = useState(0);
  const [channelReportLimit, setChannelReportLimit] = useState(10);
  const [channelReportData, setChannelReportData] = React.useState([]);
  const [channelReportCount, setChannelReportCount] = React.useState(0);
  const [channelReportLoading, setChannelReportLoading] = React.useState(true);
  const [isSortByUserName, setIsSortByUserName] = React.useState('');
  const [selectedChannelInfo, setSelectedChannelInfo] = useState<any>('');

  const createPostDataForChannelReport = useCallback((data: any, index: number): any => ({
    sno: (channelReportPageNumber) * channelReportLimit + index + 1,
    username: data.user?.username,
    reason: data.reason,
    time: getDateAndTime(data.createdAt),
  }), [channelReportLimit, channelReportPageNumber]);

  useEffect(() => {
    ChannelInfoService.getChannelReport({
      channelId: id,
      pageNumber: channelReportPageNumber + 1,
      limit: channelReportLimit,
      isSortByUserName,
    }).then((data) => {
      const jsonData: any = [];
      if (data.data !== null) {
        data.data.data.data.forEach((ele: any, index: number) => {
          jsonData.push(createPostDataForChannelReport(ele, index));
        });
      }
      setChannelReportData(jsonData); // Pagination-Count
      setChannelReportCount(data.data.data['Pagination-Count']);
      setChannelReportLoading(false);
    });
  }, [id, channelReportPageNumber, channelReportLimit, createPostDataForChannelReport, isSortByUserName]);

  const headCellsChannelReportDetails = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'reporter', label: 'Reporter Username', sort: true,
    },
    {
      id: 'reason', label: 'Reason',
    },
    {
      id: 'reporttime', label: ' Reported Date & Time',
    },
  ];

  const handleChangeChannelReportTable = (event: unknown, newPage: number) => {
    setChannelReportPageNumber(newPage);
  };

  const handleChangeRowsPerPageChannelReportTable = (event: unknown, rowNumber: number) => {
    setChannelReportLimit(rowNumber);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSortChannelReportTable = (order: any, orderBy: any) => {
    setIsSortByUserName(order === 'asc' ? '-1' : '1');
  };
  return (
    <>
      <h2 className={scss.heading_reward}>
        Channel Report Detail(
        {channelReportCount}
        )
      </h2>
      <div className={commonStyles.dataTableCover}>
        <DataTable
          headCells_={headCellsChannelReportDetails}
          rows_={channelReportData}
          rowClick={() => null}
          selected={selectedChannelInfo}
          setSelected={setSelectedChannelInfo}
          setSelectedRows={() => null}
          page={channelReportPageNumber}
          rowsPerPage={channelReportLimit}
          handleChangePage={handleChangeChannelReportTable}
          loading={channelReportLoading}
          handleSort={handleSortChannelReportTable}
          handleChangeRowsPerPage={handleChangeRowsPerPageChannelReportTable}
          count={channelReportCount}
        />
      </div>
    </>
  );
};

export default ChannelReportDetails;
