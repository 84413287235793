import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  Box,
  Typography,
} from '@material-ui/core';
import {
  FAQs,
  PrivacyPolicy,
  TermsAndConditions,
  AboutUs,
  ContactUs,
  CircularProgressLoader,
} from 'components';
import { getContentManagement } from 'actions';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import scss from './contentmanagement.module.scss';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span" variant="body2">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
  contentmanagement: any;
  fetchContentManagement: any;
}

const ContentManagement = (props: Props) => {
  const { fetchContentManagement, contentmanagement } = props;
  const { search } = useLocation();
  const { active } = queryString.parse(search);
  const [value, setValue] = React.useState(active === 'FAQ' ? 2 : 0);

  const handleChange = (event: React.SyntheticEvent, currNumber: number) => {
    setValue(currNumber);
  };

  React.useEffect(() => {
    fetchContentManagement();
  }, [fetchContentManagement]);
  return (
    <div className={commonStyles.cardBg}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={(e: any, currNumber: number) => handleChange(e, currNumber)}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            className={commonStyles.tabsUI}
          >
            <Tab className={commonStyles.tabBox} label="Privacy Policy" {...a11yProps(0)} />
            <Tab className={commonStyles.tabBox} label="Terms & conditions" {...a11yProps(1)} />
            <Tab className={commonStyles.tabBox} label="FAQs" {...a11yProps(2)} />
            <Tab className={commonStyles.tabBox} label="About us" {...a11yProps(3)} />
            <Tab className={commonStyles.tabBox} label="Contact Us" {...a11yProps(4)} />
          </Tabs>
        </Box>
        {contentmanagement.loadingInProgress || contentmanagement.data === null
          ? (
            <div className={scss.center_loader}>
              <CircularProgressLoader />
            </div>
          )
          : (
            <>
              <div className={scss.cardBody}>
                <TabPanel value={value} index={0}>
                  <PrivacyPolicy />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <TermsAndConditions />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <FAQs />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <AboutUs />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <ContactUs />
                </TabPanel>
              </div>
            </>
          )}
      </Box>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  contentmanagement: state.contentmanagement,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchContentManagement: () => dispatch(getContentManagement()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentManagement);
