import {
  put, call, takeEvery,
} from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { AuthService } from '../services';
import { ActionTypes } from '../constants';

async function fetchAsync(func: any, arg: any) {
  try {
    const response = arg !== null ? await func(arg) : await func();
    return response;
  } catch (error: any) {
    return { error: true, message: error.response.data.message };
  }
}

const formatPermission = (perm: any) => {
  const permissions: any = [];
  perm.forEach((ele:any) => {
    permissions.push({
      add: ele.add,
      edit: ele.edit,
      view: ele.view,
      delete: ele.edit,
      key: ele.moduleId.key,
    });
  });
  return permissions;
};

function* login(action: any): Generator {
  try {
    const data: any = yield call(fetchAsync, AuthService.login, action.payload);
    if (data.error) {
      yield put({ type: ActionTypes.LOGIN_ERROR, payload: data.message });
    } else {
      Cookies.set('jwt_access_token', data.data.data.accessToken);
      localStorage.setItem('adminType', data.data.data.admin.adminType);
      localStorage.setItem('username', data.data.data.admin.name);
      localStorage.setItem('permissions', JSON.stringify(formatPermission(data.data.data.permissions)));
      yield put({ type: ActionTypes.LOGIN_SUCCESS, payload: data });
    }
  } catch (e: any) {
    yield put({ type: ActionTypes.LOGIN_ERROR, payload: e });
  }
}

function* forgotPassword(action: any): Generator {
  try {
    const data: any = yield call(fetchAsync, AuthService.forgotpass, action.payload);
    if (data.error) {
      yield put({ type: ActionTypes.FORGOTPASS_ERROR, payload: data.message });
    } else {
      yield put({ type: ActionTypes.FORGOTPASS_SUCCESS, payload: data });
    }
  } catch (e: any) {
    yield put({ type: ActionTypes.FORGOTPASS_ERROR, payload: e });
  }
}

function* logout(): Generator {
  try {
    const data: any = yield call(fetchAsync, AuthService.logout, null);
    if (data.error) {
      if (data.status === 403) {
        yield put({ type: ActionTypes.LOGOUT_WITH_ERROR_REQUEST });
      } else {
        yield put({ type: ActionTypes.LOGOUT_ERROR, payload: data.message });
      }
    } else {
      Cookies.remove('jwt_access_token');
      localStorage.removeItem('adminType');
      localStorage.removeItem('permissions');
      localStorage.removeItem('username');
      yield put({ type: ActionTypes.LOGOUT_SUCCESS, payload: data });
    }
  } catch (e: any) {
    yield put({ type: ActionTypes.LOGOUT_ERROR, payload: e });
  }
}

function* logoutWithError(): Generator {
  Cookies.remove('jwt_access_token');
  localStorage.removeItem('adminType');
  yield put({ type: ActionTypes.LOGOUT_WITH_ERROR_SUCCESS });
}

export function* watchAvatarSaga() {
  yield takeEvery(ActionTypes.LOGIN_REQUEST, login);
  yield takeEvery(ActionTypes.FORGOTPASS_REQUEST, forgotPassword);
  yield takeEvery(ActionTypes.LOGOUT_REQUEST, logout);
  yield takeEvery(ActionTypes.LOGOUT_WITH_ERROR_REQUEST, logoutWithError);
}
export default watchAvatarSaga;
