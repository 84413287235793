import React from 'react';
import classNames from 'classnames';
import errorImg from '../../assets/svg/error.svg';

import css from './ValidationError.module.scss';

interface Props {
    rootClassName?: string,
    className?: string,
    error: string | undefined
}
/**
 * This component can be used to show validation errors next to form
 * input fields. The component takes the form Field component
 * `meta` object as a prop and infers if an error message should be
 * shown.
 */
const ValidationError = (props: Props) => {
  const { rootClassName, className, error } = props;
  const classes = classNames(rootClassName || css.root, className, css.errorMessage);
  return error ? (
    <div className={classes}>
      <img src={errorImg} alt="error" width="16" />
      {error}
    </div>
  ) : null;
};

ValidationError.defaultProps = { rootClassName: null, className: null };

export default ValidationError;
