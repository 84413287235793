import { Button } from 'components';
import React from 'react';
import { MyProfileService } from 'services';
import warning from '../../assets/svg/warning.svg';
import scss from './removeprofilepicture.module.scss';

interface Props{
    handleModalClose: any;
}

const RemoveProfilePicture: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
  } = props;

  const [removePicture, setRemovePicture] = React.useState(false);
  const deleteDomesService = () => {
    MyProfileService.updateProfile({ profilePicture: '' }).then(() => {
      setRemovePicture(true);
    });
  };

  return (
    <>
      {!removePicture ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            Remove Profile Picture
          </h2>
          <p>
            Are you sure you want to remove this profile picture?
          </p>
          <div className={scss.modal_footer}>
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={deleteDomesService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Profile Picture removed successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default RemoveProfilePicture;
