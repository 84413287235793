import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'utils/reactIntl';
import {
  Button,
  CircularProgressLoader,
  FieldFileInput,
  FieldTextInput,
  ValidationError,
} from 'components';
import { DomesManagementService } from 'services';
import classNames from 'classnames';
import scss from './EditDomes.module.scss';
import information from '../../assets/svg/info.svg';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  loader: any;
  handleSubmit: any;
  handleSaveAsDraft: any,
  error: string;
  id: string;
}

const EditDomesForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    intl,
    loader,
    error,
    id,
    handleSaveAsDraft,
  } = props;
  const [initialLoader, setInitialLoader] = React.useState(true);

  const editDomesSchema = yup.object().shape({
    domeName: yup.string()
      .required('Please fill all required fields'),
    description: yup.string()
      .max(250, 'Please enter characters less than 250 characters for description'),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(editDomesSchema),
  });
  useEffect(() => {
    DomesManagementService
      .getDetails(id)
      .then((data: any) => {
        formProps.reset({
          domeName: data.data.data.name,
          description: data.data.data.description,
          selectedIcon: data.data.data.selectedImage,
          unselectedIcon: data.data.data.nonSelectedImage,
        });
        setInitialLoader(false);
      });
  }, [formProps, id]);

  return (
    <>
      {initialLoader ? (
        <>
          <CircularProgressLoader />
        </>
      ) : (
        <div className={classNames(commonStyles.cardBg, scss.container)}>
          <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleSubmit)}>
            <h2 className={scss.sign_text}>
              {intl.formatMessage({ id: 'EditDomeForm.label.name' })}
            </h2>
            <FieldTextInput
              id="domeName"
              name="domeName"
              formName="editDomes"
              className="textInput"
              type="text"
              formProps={formProps}
              rootClassName={scss.passwordInput}
              label={intl.formatMessage({ id: 'EditDomeForm.label.domename' })}
            />
            <div>
              <FieldTextInput
                id="description"
                name="description"
                formName="editDomes"
                className="textInput"
                type="textarea"
                formProps={formProps}
                rootClassName={scss.passwordInput}
                label={intl.formatMessage({ id: 'EditDomeForm.label.descritpion' })}
              />
            </div>
            <h2 className={scss.sign_text}>
              {intl.formatMessage({ id: 'EditDomeForm.label.uploadLabel' })}
            </h2>
            <div className={scss.profileWrapperimg}>
              <div className={scss.selection_box}>
                <h4>
                  {intl.formatMessage({ id: 'EditDomeForm.label.selectedIcon' })}
                </h4>
                <FieldFileInput
                  id="selectedIcon"
                  name="selectedIcon"
                  className="textInput"
                  formProps={formProps}
                  rootClassName={scss.input_upload}
                />
              </div>
              <div className={scss.selection_box}>
                <h4>
                  {intl.formatMessage({ id: 'EditDomeForm.label.unselectedIcon' })}
                </h4>
                <FieldFileInput
                  id="unselectedIcon"
                  name="unselectedIcon"
                  className="textInput"
                  formProps={formProps}
                  rootClassName={scss.input_upload}
                />
              </div>
            </div>
            <p className={scss.informationUpload}>
              <img src={information} alt="information" />
              {intl.formatMessage({ id: 'EditDomeForm.label.size' })}
            </p>
            {Object.keys(formProps.formState.errors).length > 0
              && (
                <ValidationError
                  className={scss.validation_error}
                  error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
                />
              )}
            {Object.keys(formProps.formState.errors).length === 0
              && <ValidationError error={error} />}
            {loader
              ? (
                <div className={commonStyles.savingLoader}>
                  <CircularProgressLoader />
                </div>
              ) : (
                <>
                  <div className={scss.buttonWrapper}>
                    <Button
                      onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}
                    >
                      {intl.formatMessage({ id: 'EditDomeForm.label.saveAsDraft' })}
                    </Button>
                    <Button buttonType={ButtonTypes.submit}>
                      {intl.formatMessage({ id: 'EditDomeForm.label.publish' })}
                    </Button>
                  </div>
                </>
              )}
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(EditDomesForm);
