import React from 'react';
import {
  CreditActivity,
  DebitActivity,
} from 'components';
import scss from './rewardpointmanagement.module.scss';

const RewardPointDetails: React.FC = () => (
  <div className={scss.container}>
    <CreditActivity />
    <DebitActivity />
  </div>
);

export default RewardPointDetails;
