import { AddSubDomesForm, EditSubDomesForm } from 'forms';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SubDomesManagementService } from 'services';

interface ManageSubDomesInterface {
  id: string;
}

const ManageSubDomesComponent = () => {
  const [loader, setLoader] = React.useState(false);
  const { id } = useParams<ManageSubDomesInterface>();
  const history = useHistory();
  const editDome: boolean = history.location.pathname.includes('editSubDomes');
  const [error, setError] = useState('');

  const handleSubmitForm = async (data: any) => {
    setLoader(true);
    const form1 = new FormData();
    const form2 = new FormData();
    form1.append('file', data.selectedIcon[0]);
    form2.append('file', data.unselectedIcon[0]);
    const selectedImageS3Url = await SubDomesManagementService.uploadpicture(form1);
    const unselectedImageS3Url = await SubDomesManagementService.uploadpicture(form2);
    SubDomesManagementService.addDomes({
      name: data.domeName,
      description: data.description,
      parentId: data.domes,
      selectedImage: selectedImageS3Url.data.data.url,
      nonSelectedImage: unselectedImageS3Url.data.data.url,
    }).then(() => {
      setLoader(false);
      history.push('/subdomesmanagement');
    }).catch((err) => {
      setLoader(false);
      setError(err.response.data.message);
    });
  };

  const handleSubmitFormEdit = async (data: any) => {
    setError('');
    let flag = true;
    const FILE_SIZE = 50 * 1024 * 1024;
    const SUPPORTED_FORMATS = [
      'image/jpg',
      'image/jpeg',
      'image/png',
    ];
    let selectedImageS3Url: any = '';
    let unSelectedImageS3Url: any = '';
    setLoader(true);
    if (typeof (data.selectedIcon) !== 'string') {
      if (data.selectedIcon[0].size >= FILE_SIZE) {
        flag = false;
        setLoader(false);
        setError(() => 'Selected Icon size must be less than 50 Mb');
      } else if (!SUPPORTED_FORMATS.includes(data.selectedIcon[0].type)) {
        flag = false;
        setLoader(false);
        setError(() => 'Selected Icon must be of type png/jpg');
      }
    }
    if (typeof (data.unselectedIcon) !== 'string' && flag) {
      if (data.unselectedIcon[0].size >= FILE_SIZE) {
        flag = false;
        setLoader(false);
        setError(() => 'Un-SelectedIcon Icon size must be less than 50 Mb');
      } else if (!SUPPORTED_FORMATS.includes(data.unselectedIcon[0].type)) {
        flag = false;
        setLoader(false);
        setError(() => 'Un-Selected Icon must be of type png/jpg');
      }
    }
    if (flag) {
      if (typeof (data.unselectedIcon) !== 'string') {
        const form = new FormData();
        form.append('file', data.unselectedIcon[0]);
        unSelectedImageS3Url = await SubDomesManagementService.uploadpicture(form);
      }
      if (typeof (data.selectedIcon) !== 'string') {
        const form = new FormData();
        form.append('file', data.selectedIcon[0]);
        selectedImageS3Url = await SubDomesManagementService.uploadpicture(form);
      }

      SubDomesManagementService.editDomes({
        categoryId: id,
        name: data.domeName,
        description: data.description,
        parentId: data.domes,
        selectedImage: selectedImageS3Url === '' ? data.selectedIcon : selectedImageS3Url.data.data.url,
        nonSelectedImage: unSelectedImageS3Url === '' ? data.unselectedIcon : unSelectedImageS3Url.data.data.url,
        status: 'unblocked',
      }).then(() => {
        setLoader(false);
        history.push('/subdomesmanagement');
      }).catch((err) => {
        setLoader(false);
        setError(err.response.data.message);
      });
    }
  };

  const handleSaveAsDraft = async (e: any, data: any) => {
    e.preventDefault();
    setLoader(true);
    let selectedImageS3Url: any = '';
    let unSelectedImageS3Url: any = '';
    if (data.domeName.length > 0 && data.domes.length > 0) {
      if (typeof (data.selectedIcon) !== 'string' && data.selectedIcon !== null && data.selectedIcon !== undefined) {
        const form = new FormData();
        form.append('file', data.selectedIcon[0]);
        selectedImageS3Url = await SubDomesManagementService.uploadpicture(form);
      }
      if (typeof (data.unselectedIcon) !== 'string' && data.unselectedIcon !== null && data.unselectedIcon !== undefined) {
        const form = new FormData();
        form.append('file', data.unselectedIcon[0]);
        unSelectedImageS3Url = await SubDomesManagementService.uploadpicture(form);
      }

      const selectedImage = selectedImageS3Url === '' ? {} : { selectedImage: selectedImageS3Url.data.data.url };
      const nonSelectedImage = unSelectedImageS3Url === '' ? {} : { nonSelectedImage: unSelectedImageS3Url.data.data.url };

      SubDomesManagementService.saveDomesToDraft({
        name: data.domeName,
        description: data.description,
        parentId: data.domes,
        ...selectedImage,
        ...nonSelectedImage,
      }).then(() => {
        setLoader(false);
        history.push('/subdomesmanagement');
      }).catch((err) => {
        setLoader(false);
        setError(err.response.data.message);
      });
    } else {
      setLoader(false);
      setError(() => 'Domes Name and Domes cannot be empty for draft');
    }
  };
  const handleSaveAsDraftPut = async (e: any, data: any) => {
    e.preventDefault();
    setLoader(true);
    let selectedImageS3Url: any = '';
    let unSelectedImageS3Url: any = '';
    if (data.domeName.length > 0 && data.domes.length > 0) {
      if (typeof (data.selectedIcon) !== 'string' && data.selectedIcon !== null && data.selectedIcon !== undefined) {
        const form = new FormData();
        form.append('file', data.selectedIcon[0]);
        selectedImageS3Url = await SubDomesManagementService.uploadpicture(form);
      }
      if (typeof (data.unselectedIcon) !== 'string' && data.unselectedIcon !== null && data.unselectedIcon !== undefined) {
        const form = new FormData();
        form.append('file', data.unselectedIcon[0]);
        unSelectedImageS3Url = await SubDomesManagementService.uploadpicture(form);
      }

      const selectedImage = selectedImageS3Url === '' ? {} : { selectedImage: selectedImageS3Url.data.data.url };
      const nonSelectedImage = unSelectedImageS3Url === '' ? {} : { nonSelectedImage: unSelectedImageS3Url.data.data.url };

      SubDomesManagementService.saveDomesPutToDraft({
        name: data.domeName,
        description: data.description,
        parentId: data.domes,
        ...selectedImage,
        ...nonSelectedImage,
        categoryId: id,
      }).then(() => {
        setLoader(false);
        history.push('/subdomesmanagement');
      }).catch((err) => {
        setLoader(false);
        setError(err.response.data.message);
      });
    } else {
      setLoader(false);
      setError(() => 'Domes Name and Domes cannot be empty for draft');
    }
  };
  return (
    <>
      {editDome ? (
        <EditSubDomesForm
          handleSubmit={handleSubmitFormEdit}
          handleSaveAsDraftPut={handleSaveAsDraftPut}
          loader={loader}
          id={id}
          error={error}
        />
      )
        : (
          <AddSubDomesForm
            handleSubmit={handleSubmitForm}
            loader={loader}
            handleSaveAsDraft={handleSaveAsDraft}
            error={error}
          />
        )}
    </>
  );
};

export default ManageSubDomesComponent;
