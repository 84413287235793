/* eslint-disable no-underscore-dangle */
import { AddNewRolesForm, EditRole } from 'forms';
import React, { useEffect, useState } from 'react';
import { RolesManagementService } from 'services';
import { useHistory, useParams } from 'react-router-dom';
import { ToastBar, CircularProgressLoader } from 'components';

interface ManageRolesInterface {
  id: string;
}

const ManageRolesComponent = () => {
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [module, setModule] = useState<any>([]);
  const { id } = useParams<ManageRolesInterface>();
  const editRoles: boolean = history.location.pathname.includes('editRoles');
  const [roles, setRoles] = React.useState<any>();
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  const getPermissions = (data: any, key: string) => {
    let permission: any;
    data.forEach((ele: any) => {
      if (ele.moduleId.key === key) {
        permission = ele;
      }
    });
    return permission;
  };
  useEffect(() => {
    setLoader(true);
    if (editRoles) {
      RolesManagementService.getDetails(id).then((res: any) => {
        setLoader(false);
        setRoles({
          roleName: res.data.data.role.role,
          usermgmt_add: getPermissions(res.data.data.permissions, 'user_mangement').add,
          usermgmt_edit: getPermissions(res.data.data.permissions, 'user_mangement').edit,
          usermgmt_delete: getPermissions(res.data.data.permissions, 'user_mangement').delete,
          usermgmt_view: getPermissions(res.data.data.permissions, 'user_mangement').view,
          channelmgmt_add: getPermissions(res.data.data.permissions, 'channel_mangement').add,
          channelmgmt_edit: getPermissions(res.data.data.permissions, 'channel_mangement').edit,
          channelmgmt_delete: getPermissions(res.data.data.permissions, 'channel_mangement').delete,
          channelmgmt_view: getPermissions(res.data.data.permissions, 'channel_mangement').view,
          subscriptionplanmgmt_add: getPermissions(res.data.data.permissions, 'subscription_plan_management').add,
          subscriptionplanmgmt_edit: getPermissions(res.data.data.permissions, 'subscription_plan_management').edit,
          subscriptionplanmgmt_delete: getPermissions(res.data.data.permissions, 'subscription_plan_management').delete,
          subscriptionplanmgmt_view: getPermissions(res.data.data.permissions, 'subscription_plan_management').view,
          subscribedusers_add: getPermissions(res.data.data.permissions, 'subscribed_users').add,
          subscribedusers_edit: getPermissions(res.data.data.permissions, 'subscribed_users').edit,
          subscribedusers_delete: getPermissions(res.data.data.permissions, 'subscribed_users').delete,
          subscribedusers_view: getPermissions(res.data.data.permissions, 'subscribed_users').view,
          serviceprovidemgmt_add: getPermissions(res.data.data.permissions, 'service_provider_management').add,
          serviceprovidemgmt_edit: getPermissions(res.data.data.permissions, 'service_provider_management').edit,
          serviceprovidemgmt_delete: getPermissions(res.data.data.permissions, 'service_provider_management').delete,
          serviceprovidemgmt_view: getPermissions(res.data.data.permissions, 'service_provider_management').view,
          purchaseecommerce_add: getPermissions(res.data.data.permissions, 'purchase_e-commerce').add,
          purchaseecommerce_edit: getPermissions(res.data.data.permissions, 'purchase_e-commerce').edit,
          purchaseecommerce_delete: getPermissions(res.data.data.permissions, 'purchase_e-commerce').delete,
          purchaseecommerce_view: getPermissions(res.data.data.permissions, 'purchase_e-commerce').view,
          campaignmgmt_add: getPermissions(res.data.data.permissions, 'campaign_management').add,
          campaignmgmt_edit: getPermissions(res.data.data.permissions, 'campaign_management').edit,
          campaignmgmt_delete: getPermissions(res.data.data.permissions, 'campaign_management').delete,
          campaignmgmt_view: getPermissions(res.data.data.permissions, 'campaign_management').view,
          promotionsadver_add: getPermissions(res.data.data.permissions, 'promotions& _advertising').add,
          promotionsadver_edit: getPermissions(res.data.data.permissions, 'promotions& _advertising').edit,
          promotionsadver_delete: getPermissions(res.data.data.permissions, 'promotions& _advertising').delete,
          promotionsadver_view: getPermissions(res.data.data.permissions, 'promotions& _advertising').view,
          sellerbrandmgmt_add: getPermissions(res.data.data.permissions, 'seller/Brand_management').add,
          sellerbrandmgmt_edit: getPermissions(res.data.data.permissions, 'seller/Brand_management').edit,
          sellerbrandmgmt_delete: getPermissions(res.data.data.permissions, 'seller/Brand_management').delete,
          sellerbrandmgmt_view: getPermissions(res.data.data.permissions, 'seller/Brand_management').view,
          livestreaming_add: getPermissions(res.data.data.permissions, 'live_streamings').add,
          livestreaming_edit: getPermissions(res.data.data.permissions, 'live_streamings').edit,
          livestreaming_delete: getPermissions(res.data.data.permissions, 'live_streamings').delete,
          livestreaming_view: getPermissions(res.data.data.permissions, 'live_streamings').view,
          reportedpostmgmt_add: getPermissions(res.data.data.permissions, 'reported_post_management').add,
          reportedpostmgmt_edit: getPermissions(res.data.data.permissions, 'reported_post_management').edit,
          reportedpostmgmt_delete: getPermissions(res.data.data.permissions, 'reported_post_management').delete,
          reportedpostmgmt_view: getPermissions(res.data.data.permissions, 'reported_post_management').view,
          rolemgmt_add: getPermissions(res.data.data.permissions, 'role_management').add,
          rolemgmt_edit: getPermissions(res.data.data.permissions, 'role_management').edit,
          rolemgmt_delete: getPermissions(res.data.data.permissions, 'role_management').delete,
          rolemgmt_view: getPermissions(res.data.data.permissions, 'role_management').view,
          subadminmgmt_add: getPermissions(res.data.data.permissions, 'sub-Admin_management').add,
          subadminmgmt_edit: getPermissions(res.data.data.permissions, 'sub-Admin_management').edit,
          subadminmgmt_delete: getPermissions(res.data.data.permissions, 'sub-Admin_management').delete,
          subadminmgmt_view: getPermissions(res.data.data.permissions, 'sub-Admin_management').view,
          eventmgmt_add: getPermissions(res.data.data.permissions, 'event_management').add,
          eventmgmt_edit: getPermissions(res.data.data.permissions, 'event_management').edit,
          eventmgmt_delete: getPermissions(res.data.data.permissions, 'event_management').delete,
          eventmgmt_view: getPermissions(res.data.data.permissions, 'event_management').view,
          paymentmgmt_add: getPermissions(res.data.data.permissions, 'payment_management').add,
          paymentmgmt_edit: getPermissions(res.data.data.permissions, 'payment_management').edit,
          paymentmgmt_delete: getPermissions(res.data.data.permissions, 'payment_management').delete,
          paymentmgmt_view: getPermissions(res.data.data.permissions, 'payment_management').view,
          coinactivitymgmt_add: getPermissions(res.data.data.permissions, 'coin_activity_anagement').add,
          coinactivitymgmt_edit: getPermissions(res.data.data.permissions, 'coin_activity_anagement').edit,
          coinactivitymgmt_delete: getPermissions(res.data.data.permissions, 'coin_activity_anagement').delete,
          coinactivitymgmt_view: getPermissions(res.data.data.permissions, 'coin_activity_anagement').view,
          commissionmgmt_add: getPermissions(res.data.data.permissions, 'commission_management').add,
          commissionmgmt_edit: getPermissions(res.data.data.permissions, 'commission_management').edit,
          commissionmgmt_delete: getPermissions(res.data.data.permissions, 'commission_management').delete,
          commissionmgmt_view: getPermissions(res.data.data.permissions, 'commission_management').view,
          rewardpoints_add: getPermissions(res.data.data.permissions, 'reward_points').add,
          rewardpoints_edit: getPermissions(res.data.data.permissions, 'reward_points').edit,
          rewardpoints_delete: getPermissions(res.data.data.permissions, 'reward_points').delete,
          rewardpoints_view: getPermissions(res.data.data.permissions, 'reward_points').view,
          categories_add: getPermissions(res.data.data.permissions, 'categories').add,
          categories_edit: getPermissions(res.data.data.permissions, 'categories').edit,
          categories_delete: getPermissions(res.data.data.permissions, 'categories').delete,
          categories_view: getPermissions(res.data.data.permissions, 'categories').view,
          subcategorymgmt_add: getPermissions(res.data.data.permissions, 'sub_categories').add,
          subcategorymgmt_edit: getPermissions(res.data.data.permissions, 'sub_categories').edit,
          subcategorymgmt_delete: getPermissions(res.data.data.permissions, 'sub_categories').delete,
          subcategorymgmt_view: getPermissions(res.data.data.permissions, 'sub_categories').view,
          issuesreported_add: getPermissions(res.data.data.permissions, 'issues_reported').add,
          issuesreported_edit: getPermissions(res.data.data.permissions, 'issues_reported').edit,
          issuesreported_delete: getPermissions(res.data.data.permissions, 'issues_reported').delete,
          issuesreported_view: getPermissions(res.data.data.permissions, 'issues_reported').view,
          versionmanagement_add: getPermissions(res.data.data.permissions, 'version_management').add,
          versionmanagement_edit: getPermissions(res.data.data.permissions, 'version_management').edit,
          versionmanagement_delete: getPermissions(res.data.data.permissions, 'version_management').delete,
          versionmanagement_view: getPermissions(res.data.data.permissions, 'version_management').view,
          reminderemails_add: getPermissions(res.data.data.permissions, 'reminder_emails').add,
          reminderemails_edit: getPermissions(res.data.data.permissions, 'reminder_emails').edit,
          reminderemails_delete: getPermissions(res.data.data.permissions, 'reminder_emails').delete,
          reminderemails_view: getPermissions(res.data.data.permissions, 'reminder_emails').view,
          contentmgmt_add: getPermissions(res.data.data.permissions, 'content_management').add,
          contentmgmt_edit: getPermissions(res.data.data.permissions, 'content_management').edit,
          contentmgmt_delete: getPermissions(res.data.data.permissions, 'content_management').delete,
          contentmgmt_view: getPermissions(res.data.data.permissions, 'content_management').view,
          notificationmmt_add: getPermissions(res.data.data.permissions, 'notification_management').add,
          notificationmmt_edit: getPermissions(res.data.data.permissions, 'notification_management').edit,
          notificationmmt_delete: getPermissions(res.data.data.permissions, 'notification_management').delete,
          notificationmmt_view: getPermissions(res.data.data.permissions, 'notification_management').view,
          support_add: getPermissions(res.data.data.permissions, 'support').add,
          support_edit: getPermissions(res.data.data.permissions, 'support').edit,
          support_delete: getPermissions(res.data.data.permissions, 'support').delete,
          support_view: getPermissions(res.data.data.permissions, 'support').view,
          feedback_add: getPermissions(res.data.data.permissions, 'feedback').add,
          feedback_edit: getPermissions(res.data.data.permissions, 'feedback').edit,
          feedback_delete: getPermissions(res.data.data.permissions, 'feedback').delete,
          feedback_view: getPermissions(res.data.data.permissions, 'feedback').view,
          reportmgmt_add: getPermissions(res.data.data.permissions, 'report_management').add,
          reportmgmt_edit: getPermissions(res.data.data.permissions, 'report_management').edit,
          reportmgmt_delete: getPermissions(res.data.data.permissions, 'report_management').delete,
          reportmgmt_view: getPermissions(res.data.data.permissions, 'report_management').view,
        });
      });
    }
    RolesManagementService.getModulesList().then((res) => {
      const data: any = [];
      setLoader(false);
      res.data.data.forEach((ele: any) => {
        data.push({ key: ele._id, label: ele.key });
      });
      setModule(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getModuleId = (str: any) => {
    let keyValue: any;
    module.forEach((ele: any) => {
      if (ele.label === str) {
        keyValue = ele.key;
      }
    });
    return keyValue;
  };
  const handleSubmitForm = (data: any) => {
    const newRole = {
      role: data.roleName,
      permissions: [{
        moduleId: getModuleId('user_mangement'),
        add: data.usermgmt_add,
        edit: data.usermgmt_edit,
        delete: data.usermgmt_delete,
        view: data.usermgmt_view,
      },
      {
        moduleId: getModuleId('channel_mangement'),
        add: data.channelmgmt_add,
        edit: data.channelmgmt_edit,
        delete: data.channelmgmt_delete,
        view: data.channelmgmt_view,
      },
      {
        moduleId: getModuleId('subscription_plan_management'),
        add: data.subscriptionplanmgmt_add,
        edit: data.subscriptionplanmgmt_edit,
        delete: data.subscriptionplanmgmt_delete,
        view: data.subscriptionplanmgmt_view,
      },
      {
        moduleId: getModuleId('subscribed_users'),
        add: data.subscribedusers_add,
        edit: data.subscribedusers_edit,
        delete: data.subscribedusers_delete,
        view: data.subscribedusers_view,
      },
      {
        moduleId: getModuleId('service_provider_management'),
        add: data.serviceprovidemgmt_add,
        edit: data.serviceprovidemgmt_edit,
        delete: data.serviceprovidemgmt_delete,
        view: data.serviceprovidemgmt_view,
      },
      {
        moduleId: getModuleId('purchase_e-commerce'),
        add: data.purchaseecommerce_add,
        edit: data.purchaseecommerce_edit,
        delete: data.purchaseecommerce_delete,
        view: data.purchaseecommerce_view,
      },
      {
        moduleId: getModuleId('campaign_management'),
        add: data.campaignmgmt_add,
        edit: data.campaignmgmt_edit,
        delete: data.campaignmgmt_delete,
        view: data.campaignmgmt_view,
      },
      {
        moduleId: getModuleId('promotions& _advertising'),
        add: data.promotionsadver_add,
        edit: data.promotionsadver_edit,
        delete: data.promotionsadver_delete,
        view: data.promotionsadver_view,
      },
      {
        moduleId: getModuleId('seller/Brand_management'),
        add: data.sellerbrandmgmt_add,
        edit: data.sellerbrandmgmt_edit,
        delete: data.sellerbrandmgmt_delete,
        view: data.sellerbrandmgmt_view,
      },
      {
        moduleId: getModuleId('live_streamings'),
        add: data.livestreaming_add,
        edit: data.livestreaming_edit,
        delete: data.livestreaming_delete,
        view: data.livestreaming_view,
      },
      {
        moduleId: getModuleId('reported_post_management'),
        add: data.reportedpostmgmt_add,
        edit: data.reportedpostmgmt_edit,
        delete: data.reportedpostmgmt_delete,
        view: data.reportedpostmgmt_view,
      },
      {
        moduleId: getModuleId('role_management'),
        add: data.rolemgmt_add,
        edit: data.rolemgmt_edit,
        delete: data.rolemgmt_delete,
        view: data.rolemgmt_view,
      },
      {
        moduleId: getModuleId('sub-Admin_management'),
        add: data.subadminmgmt_add,
        edit: data.subadminmgmt_edit,
        delete: data.subadminmgmt_delete,
        view: data.subadminmgmt_view,
      },
      {
        moduleId: getModuleId('event_management'),
        add: data.eventmgmt_add,
        edit: data.eventmgmt_edit,
        delete: data.eventmgmt_delete,
        view: data.eventmgmt_view,
      },
      {
        moduleId: getModuleId('payment_management'),
        add: data.paymentmgmt_add,
        edit: data.paymentmgmt_edit,
        delete: data.paymentmgmt_delete,
        view: data.paymentmgmt_view,
      },
      {
        moduleId: getModuleId('coin_activity_anagement'),
        add: data.coinactivitymgmt_add,
        edit: data.coinactivitymgmt_edit,
        delete: data.coinactivitymgmt_delete,
        view: data.coinactivitymgmt_view,
      },
      {
        moduleId: getModuleId('commission_management'),
        add: data.commissionmgmt_add,
        edit: data.commissionmgmt_edit,
        delete: data.commissionmgmt_delete,
        view: data.commissionmgmt_view,
      },
      {
        moduleId: getModuleId('reward_points'),
        add: data.rewardpoints_add,
        edit: data.rewardpoints_edit,
        delete: data.rewardpoints_delete,
        view: data.rewardpoints_view,
      },
      {
        moduleId: getModuleId('categories'),
        add: data.categories_add,
        edit: data.categories_edit,
        delete: data.categories_delete,
        view: data.categories_view,
      },
      {
        moduleId: getModuleId('sub_categories'),
        add: data.subcategorymgmt_add,
        edit: data.subcategorymgmt_edit,
        delete: data.subcategorymgmt_delete,
        view: data.subcategorymgmt_view,
      },
      {
        moduleId: getModuleId('issues_reported'),
        add: data.issuesreported_add,
        edit: data.issuesreported_edit,
        delete: data.issuesreported_delete,
        view: data.issuesreported_view,
      },
      {
        moduleId: getModuleId('version_management'),
        add: data.versionmanagement_add,
        edit: data.versionmanagement_edit,
        delete: data.versionmanagement_delete,
        view: data.versionmanagement_view,
      }, {
        moduleId: getModuleId('reminder_emails'),
        add: data.reminderemails_add,
        edit: data.reminderemails_edit,
        delete: data.reminderemails_delete,
        view: data.reminderemails_view,
      },
      {
        moduleId: getModuleId('content_management'),
        add: data.contentmgmt_add,
        edit: data.contentmgmt_edit,
        delete: data.contentmgmt_delete,
        view: data.contentmgmt_view,
      },
      {
        moduleId: getModuleId('notification_management'),
        add: data.notificationmmt_add,
        edit: data.notificationmmt_edit,
        delete: data.notificationmmt_delete,
        view: data.notificationmmt_view,
      },
      {
        moduleId: getModuleId('support'),
        add: data.support_add,
        edit: data.support_edit,
        delete: data.support_delete,
        view: data.support_view,
      },
      {
        moduleId: getModuleId('feedback'),
        add: data.feedback_add,
        edit: data.feedback_edit,
        delete: data.feedback_delete,
        view: data.feedback_view,
      },
      {
        moduleId: getModuleId('report_management'),
        add: data.reportmgmt_add,
        edit: data.reportmgmt_edit,
        delete: data.reportmgmt_delete,
        view: data.reportmgmt_view,
      },
      ],
    };
    RolesManagementService.addRole(newRole).then(() => {
      history.push('/rolemanagement');
    }).catch((err: any) => {
      setNotification({
        ...notification,
        show: true,
        message: err.response.data.message,
      });
    });
  };
  const handleUpdateForm = (data: any) => {
    const newRole = {
      role: data.roleName,
      permissions: [{
        moduleId: getModuleId('user_mangement'),
        add: data.usermgmt_add,
        edit: data.usermgmt_edit,
        delete: data.usermgmt_delete,
        view: data.usermgmt_view,
      },
      {
        moduleId: getModuleId('channel_mangement'),
        add: data.channelmgmt_add,
        edit: data.channelmgmt_edit,
        delete: data.channelmgmt_delete,
        view: data.channelmgmt_view,
      },
      {
        moduleId: getModuleId('subscription_plan_management'),
        add: data.subscriptionplanmgmt_add,
        edit: data.subscriptionplanmgmt_edit,
        delete: data.subscriptionplanmgmt_delete,
        view: data.subscriptionplanmgmt_view,
      },
      {
        moduleId: getModuleId('subscribed_users'),
        add: data.subscribedusers_add,
        edit: data.subscribedusers_edit,
        delete: data.subscribedusers_delete,
        view: data.subscribedusers_view,
      },
      {
        moduleId: getModuleId('service_provider_management'),
        add: data.serviceprovidemgmt_add,
        edit: data.serviceprovidemgmt_edit,
        delete: data.serviceprovidemgmt_delete,
        view: data.serviceprovidemgmt_view,
      },
      {
        moduleId: getModuleId('purchase_e-commerce'),
        add: data.purchaseecommerce_add,
        edit: data.purchaseecommerce_edit,
        delete: data.purchaseecommerce_delete,
        view: data.purchaseecommerce_view,
      },
      {
        moduleId: getModuleId('campaign_management'),
        add: data.campaignmgmt_add,
        edit: data.campaignmgmt_edit,
        delete: data.campaignmgmt_delete,
        view: data.campaignmgmt_view,
      },
      {
        moduleId: getModuleId('promotions& _advertising'),
        add: data.promotionsadver_add,
        edit: data.promotionsadver_edit,
        delete: data.promotionsadver_delete,
        view: data.promotionsadver_view,
      },
      {
        moduleId: getModuleId('seller/Brand_management'),
        add: data.sellerbrandmgmt_add,
        edit: data.sellerbrandmgmt_edit,
        delete: data.sellerbrandmgmt_delete,
        view: data.sellerbrandmgmt_view,
      },
      {
        moduleId: getModuleId('live_streamings'),
        add: data.livestreaming_add,
        edit: data.livestreaming_edit,
        delete: data.livestreaming_delete,
        view: data.livestreaming_view,
      },
      {
        moduleId: getModuleId('reported_post_management'),
        add: data.reportedpostmgmt_add,
        edit: data.reportedpostmgmt_edit,
        delete: data.reportedpostmgmt_delete,
        view: data.reportedpostmgmt_view,
      },
      {
        moduleId: getModuleId('role_management'),
        add: data.rolemgmt_add,
        edit: data.rolemgmt_edit,
        delete: data.rolemgmt_delete,
        view: data.rolemgmt_view,
      },
      {
        moduleId: getModuleId('sub-Admin_management'),
        add: data.subadminmgmt_add,
        edit: data.subadminmgmt_edit,
        delete: data.subadminmgmt_delete,
        view: data.subadminmgmt_view,
      },
      {
        moduleId: getModuleId('event_management'),
        add: data.eventmgmt_add,
        edit: data.eventmgmt_edit,
        delete: data.eventmgmt_delete,
        view: data.eventmgmt_view,
      },
      {
        moduleId: getModuleId('payment_management'),
        add: data.paymentmgmt_add,
        edit: data.paymentmgmt_edit,
        delete: data.paymentmgmt_delete,
        view: data.paymentmgmt_view,
      },
      {
        moduleId: getModuleId('coin_activity_anagement'),
        add: data.coinactivitymgmt_add,
        edit: data.coinactivitymgmt_edit,
        delete: data.coinactivitymgmt_delete,
        view: data.coinactivitymgmt_view,
      },
      {
        moduleId: getModuleId('commission_management'),
        add: data.commissionmgmt_add,
        edit: data.commissionmgmt_edit,
        delete: data.commissionmgmt_delete,
        view: data.commissionmgmt_view,
      },
      {
        moduleId: getModuleId('reward_points'),
        add: data.rewardpoints_add,
        edit: data.rewardpoints_edit,
        delete: data.rewardpoints_delete,
        view: data.rewardpoints_view,
      },
      {
        moduleId: getModuleId('categories'),
        add: data.categories_add,
        edit: data.categories_edit,
        delete: data.categories_delete,
        view: data.categories_view,
      },
      {
        moduleId: getModuleId('sub_categories'),
        add: data.subcategorymgmt_add,
        edit: data.subcategorymgmt_edit,
        delete: data.subcategorymgmt_delete,
        view: data.subcategorymgmt_view,
      },
      {
        moduleId: getModuleId('issues_reported'),
        add: data.issuesreported_add,
        edit: data.issuesreported_edit,
        delete: data.issuesreported_delete,
        view: data.issuesreported_view,
      },
      {
        moduleId: getModuleId('version_management'),
        add: data.versionmanagement_add,
        edit: data.versionmanagement_edit,
        delete: data.versionmanagement_delete,
        view: data.versionmanagement_view,
      }, {
        moduleId: getModuleId('reminder_emails'),
        add: data.reminderemails_add,
        edit: data.reminderemails_edit,
        delete: data.reminderemails_delete,
        view: data.reminderemails_view,
      },
      {
        moduleId: getModuleId('content_management'),
        add: data.contentmgmt_add,
        edit: data.contentmgmt_edit,
        delete: data.contentmgmt_delete,
        view: data.contentmgmt_view,
      },
      {
        moduleId: getModuleId('notification_management'),
        add: data.notificationmmt_add,
        edit: data.notificationmmt_edit,
        delete: data.notificationmmt_delete,
        view: data.notificationmmt_view,
      },
      {
        moduleId: getModuleId('support'),
        add: data.support_add,
        edit: data.support_edit,
        delete: data.support_delete,
        view: data.support_view,
      },
      {
        moduleId: getModuleId('feedback'),
        add: data.feedback_add,
        edit: data.feedback_edit,
        delete: data.feedback_delete,
        view: data.feedback_view,
      },
      {
        moduleId: getModuleId('report_management'),
        add: data.reportmgmt_add,
        edit: data.reportmgmt_edit,
        delete: data.reportmgmt_delete,
        view: data.reportmgmt_view,
      },
      ],
    };
    RolesManagementService.updateRoles({ roleId: id, ...newRole })
      .then(() => {
        history.push('/rolemanagement');
      })
      .catch((err: any) => {
        setNotification({
          ...notification,
          show: true,
          message: err.response.data.message,
        });
      });
  };
  const handleSaveAsDraft = (e: any, data: any) => {
    e.preventDefault();
    const newRole = {
      role: data.roleName,
      permissions: [{
        moduleId: getModuleId('user_mangement'),
        add: data.usermgmt_add,
        edit: data.usermgmt_edit,
        delete: data.usermgmt_delete,
        view: data.usermgmt_view,
      },
      {
        moduleId: getModuleId('channel_mangement'),
        add: data.channelmgmt_add,
        edit: data.channelmgmt_edit,
        delete: data.channelmgmt_delete,
        view: data.channelmgmt_view,
      },
      {
        moduleId: getModuleId('subscription_plan_management'),
        add: data.subscriptionplanmgmt_add,
        edit: data.subscriptionplanmgmt_edit,
        delete: data.subscriptionplanmgmt_delete,
        view: data.subscriptionplanmgmt_view,
      },
      {
        moduleId: getModuleId('subscribed_users'),
        add: data.subscribedusers_add,
        edit: data.subscribedusers_edit,
        delete: data.subscribedusers_delete,
        view: data.subscribedusers_view,
      },
      {
        moduleId: getModuleId('service_provider_management'),
        add: data.serviceprovidemgmt_add,
        edit: data.serviceprovidemgmt_edit,
        delete: data.serviceprovidemgmt_delete,
        view: data.serviceprovidemgmt_view,
      },
      {
        moduleId: getModuleId('purchase_e-commerce'),
        add: data.purchaseecommerce_add,
        edit: data.purchaseecommerce_edit,
        delete: data.purchaseecommerce_delete,
        view: data.purchaseecommerce_view,
      },
      {
        moduleId: getModuleId('campaign_management'),
        add: data.campaignmgmt_add,
        edit: data.campaignmgmt_edit,
        delete: data.campaignmgmt_delete,
        view: data.campaignmgmt_view,
      },
      {
        moduleId: getModuleId('promotions& _advertising'),
        add: data.promotionsadver_add,
        edit: data.promotionsadver_edit,
        delete: data.promotionsadver_delete,
        view: data.promotionsadver_view,
      },
      {
        moduleId: getModuleId('seller/Brand_management'),
        add: data.sellerbrandmgmt_add,
        edit: data.sellerbrandmgmt_edit,
        delete: data.sellerbrandmgmt_delete,
        view: data.sellerbrandmgmt_view,
      },
      {
        moduleId: getModuleId('live_streamings'),
        add: data.livestreaming_add,
        edit: data.livestreaming_edit,
        delete: data.livestreaming_delete,
        view: data.livestreaming_view,
      },
      {
        moduleId: getModuleId('reported_post_management'),
        add: data.reportedpostmgmt_add,
        edit: data.reportedpostmgmt_edit,
        delete: data.reportedpostmgmt_delete,
        view: data.reportedpostmgmt_view,
      },
      {
        moduleId: getModuleId('role_management'),
        add: data.rolemgmt_add,
        edit: data.rolemgmt_edit,
        delete: data.rolemgmt_delete,
        view: data.rolemgmt_view,
      },
      {
        moduleId: getModuleId('sub-Admin_management'),
        add: data.subadminmgmt_add,
        edit: data.subadminmgmt_edit,
        delete: data.subadminmgmt_delete,
        view: data.subadminmgmt_view,
      },
      {
        moduleId: getModuleId('event_management'),
        add: data.eventmgmt_add,
        edit: data.eventmgmt_edit,
        delete: data.eventmgmt_delete,
        view: data.eventmgmt_view,
      },
      {
        moduleId: getModuleId('payment_management'),
        add: data.paymentmgmt_add,
        edit: data.paymentmgmt_edit,
        delete: data.paymentmgmt_delete,
        view: data.paymentmgmt_view,
      },
      {
        moduleId: getModuleId('coin_activity_anagement'),
        add: data.coinactivitymgmt_add,
        edit: data.coinactivitymgmt_edit,
        delete: data.coinactivitymgmt_delete,
        view: data.coinactivitymgmt_view,
      },
      {
        moduleId: getModuleId('commission_management'),
        add: data.commissionmgmt_add,
        edit: data.commissionmgmt_edit,
        delete: data.commissionmgmt_delete,
        view: data.commissionmgmt_view,
      },
      {
        moduleId: getModuleId('reward_points'),
        add: data.rewardpoints_add,
        edit: data.rewardpoints_edit,
        delete: data.rewardpoints_delete,
        view: data.rewardpoints_view,
      },
      {
        moduleId: getModuleId('categories'),
        add: data.categories_add,
        edit: data.categories_edit,
        delete: data.categories_delete,
        view: data.categories_view,
      },
      {
        moduleId: getModuleId('sub_categories'),
        add: data.subcategorymgmt_add,
        edit: data.subcategorymgmt_edit,
        delete: data.subcategorymgmt_delete,
        view: data.subcategorymgmt_view,
      },
      {
        moduleId: getModuleId('issues_reported'),
        add: data.issuesreported_add,
        edit: data.issuesreported_edit,
        delete: data.issuesreported_delete,
        view: data.issuesreported_view,
      },
      {
        moduleId: getModuleId('version_management'),
        add: data.versionmanagement_add,
        edit: data.versionmanagement_edit,
        delete: data.versionmanagement_delete,
        view: data.versionmanagement_view,
      }, {
        moduleId: getModuleId('reminder_emails'),
        add: data.reminderemails_add,
        edit: data.reminderemails_edit,
        delete: data.reminderemails_delete,
        view: data.reminderemails_view,
      },
      {
        moduleId: getModuleId('content_management'),
        add: data.contentmgmt_add,
        edit: data.contentmgmt_edit,
        delete: data.contentmgmt_delete,
        view: data.contentmgmt_view,
      },
      {
        moduleId: getModuleId('notification_management'),
        add: data.notificationmmt_add,
        edit: data.notificationmmt_edit,
        delete: data.notificationmmt_delete,
        view: data.notificationmmt_view,
      },
      {
        moduleId: getModuleId('support'),
        add: data.support_add,
        edit: data.support_edit,
        delete: data.support_delete,
        view: data.support_view,
      },
      {
        moduleId: getModuleId('feedback'),
        add: data.feedback_add,
        edit: data.feedback_edit,
        delete: data.feedback_delete,
        view: data.feedback_view,
      },
      {
        moduleId: getModuleId('report_management'),
        add: data.reportmgmt_add,
        edit: data.reportmgmt_edit,
        delete: data.reportmgmt_delete,
        view: data.reportmgmt_view,
      },
      ],
      status: 'draft',
    };
    RolesManagementService.addRole(newRole).then(() => {
      history.push('/rolemanagement');
    }).catch((err: any) => {
      setNotification({
        ...notification,
        show: true,
        message: err.response.data.message,
      });
    });
  };
  return (
    <>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      {loader ? <CircularProgressLoader /> : (
        <>
          {editRoles ? (
            <EditRole
              handleSubmit={handleUpdateForm}
              handleSaveAsDraft={handleSaveAsDraft}
              loader={loader}
              roles={roles}
            />
          ) : (
            <AddNewRolesForm
              handleSubmit={handleSubmitForm}
              handleSaveAsDraft={handleSaveAsDraft}
              loader={loader}
            />
          )}
        </>
      )}
    </>
  );
};

export default ManageRolesComponent;
