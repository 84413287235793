import api from 'api';

export class SubAdminManagementService {
  static async getSubAdminList(payload: any) {
    const {
      page,
      rowsPerPage,
      search,
      isSortByName,
      isSortByCreated,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${search !== '' ? `&search=${search}` : ''}${isSortByName !== '' ? `&isSortByName=${isSortByName}` : ''}${isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}`;
    return api.getApiCall(`api/v1/admins?${apiEndPoint}`);
  }

  static async addRole(payload: any) {
    return api.postApiCall('api/v1/admins', payload);
  }

  static async updateRole(payload: any) {
    return api.putApiCall('api/v1/admins', payload);
  }

  static async getDetail(payload: any) {
    return api.getApiCall(`/api/v1/admins/${payload}`);
  }

  static async deleteAdmin(payload: any) {
    return api.deleteApiCall('/api/v1/admins', payload);
  }

  static async blockAdmin(payload: any) {
    return api.putApiCall('/api/v1/admins/activate-deactivate', payload);
  }

  static async exportSubAdmin(payload: any) {
    const {
      search,
      isSortByName,
      isSortByCreated,
      type,
    } = payload;
    const apiEndPoint = `${search !== '' ? `&search=${search}` : ''}${isSortByName !== '' && isSortByName !== undefined ? `&isSortByName=${isSortByName}` : ''}${isSortByCreated !== '' && isSortByCreated !== undefined ? `&isSortByCreated=${isSortByCreated}` : ''}&type=${type}`;
    return api.getApiCall(`/api/v1/admins/exports?${apiEndPoint}`);
  }
}
