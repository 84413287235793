import React from 'react';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import searchBarApis from 'utils/searchBarApis';
import {
  Button,
  FieldSelect,
  ValidationError,
  FieldSearchBar,
  CircularProgressLoader,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
} from '../../components';
import DomesTypes from './DomesManagement.json';
import exp from '../../assets/svg/export.svg';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  handleFormSubmit: any,
  handleExport: any,
  intl: any,
  error: any,
  loaderExport: boolean,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const DomesManagementFilterForm: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    intl,
    error,
    handleExport,
    loaderExport,
  } = props;
  const [toggle, setToggle] = React.useState(false);
  const formProps = useForm();
  const handleReset = () => {
    formProps.reset({
      search: '',
      userType: '',
    });
  };
  return (
    <form className={commonStyles.filterForm} onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <div className={commonStyles.filter_container}>
        <div className={commonStyles.filterSearch}>
          <div>
            <div className={commonStyles.userType}>
              <FieldSelect
                id="userType"
                name="userType"
                variant={false}
                label={intl.formatMessage({ id: 'DomesManagement.label.status' })}
                formProps={formProps}
                options={DomesTypes}
              />
            </div>
            <div className={commonStyles.searchField}>
              <FieldSearchBar
                searchURL={searchBarApis.domesManagement}
                formProps={formProps}
                name="search"
                label={intl.formatMessage({ id: 'DomesManagement.label.search' })}
                id="search"
              />
            </div>
          </div>
          <AccessControl
            permissionKey={PERMISSION_KEYS.CATERGORIES}
            accessType={PERMISSION_TYPES.EDIT}
          >
            <div className={commonStyles.userRightExport}>
              {!loaderExport
                ? (
                  <>
                    <span>
                      <img
                        src={exp}
                        alt="export"
                        role="presentation"
                        onClick={() => setToggle((prevState: boolean) => !prevState)}
                      />
                    </span>
                    {toggle && (
                      <ul className={commonStyles.userrigthToggle}>
                        <li
                          role="presentation"
                          onClick={() => handleExport('XLSX')}
                        >
                          {intl.formatMessage({ id: 'DomesManagement.label.expot.xlsx' })}
                        </li>
                        <li
                          role="presentation"
                          onClick={() => handleExport('CSV')}
                        >
                          {intl.formatMessage({ id: 'DomesManagement.label.export.csv' })}
                        </li>
                      </ul>
                    )}
                  </>
                ) : <CircularProgressLoader />}
            </div>
          </AccessControl>
        </div>
        <div className={commonStyles.filterOptions}>
          <div className={commonStyles.buttonWrapper}>
            <Button buttonType={ButtonTypes.submit} className={commonStyles.button}>
              {intl.formatMessage({ id: 'DomesManagement.label.apply' })}
            </Button>
            <Button onClick={handleReset} className={commonStyles.button}>
              {intl.formatMessage({ id: 'DomesManagement.label.reset' })}
            </Button>
          </div>
        </div>
        {error && (
          <ValidationError
            className={commonStyles.validation_error}
            error={error}
          />
        )}
      </div>
    </form>
  );
};

export default injectIntl(DomesManagementFilterForm);
