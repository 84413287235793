import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'utils/reactIntl';
import { connect } from 'react-redux';
import { FAQFilters } from 'forms';
import { getDateAndTime } from 'utils/dateMethods';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { DeleteFAQS, Modal, CircularProgressLoader } from 'components';
import Parser from 'html-react-parser';
import { faqs } from '../../actions';
import edit from '../../assets/svg/edit.svg';
import scss from './faqs.module.scss';
import blockIcon from '../../assets/svg/block.svg';
import unblockIcon from '../../assets/svg/unblock.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import active from '../../assets/svg/active-g.svg';
import block from '../../assets/svg/blocked.svg';

interface QASInterface {
  date: string;
  question: string;
  answer: string;
  status: string;
  id: string;
  handleOperations: any;
}

const QAS: React.FC<QASInterface> = (props: QASInterface) => {
  const {
    date,
    question,
    answer,
    status,
    id,
    handleOperations,
  } = props;
  return (
    <>
      <div className={scss.faqBox}>
        <div className={scss.faqBoxHead}>
          <p>
            <span>Added On:</span>
            {date}
          </p>
          <div className={scss.faqBoxaction}>
            <img
              src={edit}
              role="presentation"
              alt="block"
              width="35"
              title="Edit"
              height="35"
              onClick={() => handleOperations('edit', id)}
            />
            <img
              src={deleteIcon}
              role="presentation"
              alt="block"
              width="35"
              title="Delete"
              height="35"
              onClick={() => handleOperations('delete', id)}
            />
            {status !== 'draft' && (
              <img
                src={status === 'unblocked' ? blockIcon : unblockIcon}
                alt={status === 'unblocked' ? 'blockIcon' : 'unblockIcon'}
                width="35"
                title={status === 'unblocked' ? 'unblock' : 'block'}
                height="35"
                role="presentation"
                onClick={() => handleOperations(status === 'unblocked'
                  ? 'blocked' : 'unblocked', id)}
              />
            )}
            {status === 'unblocked' ? (
              <img
                src={active}
                alt="active"
                title="active"
              />
            ) : (
              <img
                src={block}
                alt="blocked"
                title="blocked"
              />
            )}
          </div>
        </div>
        <div className={scss.qstAns}>
          <div>
            <h2>
              <span>Q.</span>
              {' '}
              {Parser(question)}
            </h2>
          </div>
          <div>
            {Parser(answer)}
          </div>
        </div>
      </div>
    </>
  );
};

interface Props {
  fetchFaqs: any;
  faqsList: any;
}
const FQASComponent: React.FC<Props> = (props: Props) => {
  const { fetchFaqs, faqsList } = props;
  const [filters, setFilters] = React.useState<any>({
    page: 1,
    limit: 10,
    search: '',
    startDate: '',
    endDate: '',
    status: '',
  });
  const history = useHistory();
  const [operationsId, setOperationsId] = React.useState('');
  const [deleteState, setDeleteState] = React.useState(false);
  const [blockState, setBlockState] = React.useState(false);
  const [unblock, setUnBlock] = React.useState(false);
  const [scrollingData, setScrollingData] = React.useState<any>([]);
  React.useEffect(() => {
    fetchFaqs(filters);
  }, [fetchFaqs, filters]);

  React.useEffect(() => {
    if (faqsList.data !== null && !faqsList.loadingInProgress) {
      setScrollingData((prevState: any) => [...prevState, ...faqsList.data.data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqsList]);

  const [showModal, setShowModal] = React.useState(false);
  const handleFormSubmit = (values: any) => {
    setScrollingData([]);
    setFilters({
      ...filters,
      page: 1,
      search: values.search,
      startDate: values.startDate !== null ? values.startDate.toISOString() : '',
      endDate: values.endDate !== null ? values.endDate.toISOString() : '',
      status: values.status,
    });
  };
  const handleModalClose = () => {
    setShowModal(false);
    setDeleteState(false);
    setBlockState(false);
    setUnBlock(false);
  };

  const handleOperations = (type: string, id: string) => {
    setOperationsId(id);
    setShowModal(true);
    switch (type) {
      case 'edit':
        history.push(`/contentmanagement/${id}`);
        break;
      case 'delete':
        setDeleteState(true);
        break;
      case 'blocked':
        setBlockState(true);
        break;
      case 'unblocked':
        setUnBlock(true);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {deleteState && (
          <DeleteFAQS
            handleModalClose={() => {
              fetchFaqs();
              handleModalClose();
            }}
            id={operationsId}
          />
        )}
        {blockState && <h1>Block State</h1>}
        {unblock && <h1>Unblock</h1>}
      </Modal>
      <FAQFilters
        handleFormSubmit={handleFormSubmit}
        error=""
      />
      <h2 className={scss.contentHeading}>
        FAQs
        <button
          type="button"
          className={scss.headingEdit}
          onClick={() => history.push('/contentmanagement/addFAQS')}
        >
          Add FAQ
        </button>
      </h2>
      {
        scrollingData.length === 0
        && !faqsList.loadingInProgress
        && <h3>No Record Found</h3>
      }
      {faqsList.data === null || scrollingData === undefined ? (
        <div className={scss.center_loader}>
          <CircularProgressLoader />
        </div>
      )
        : (
          <InfiniteScroll
            dataLength={scrollingData.length}
            next={() => {
              setFilters({ ...filters, page: filters.page + 1 });
            }}
            hasMore
            loader={(
              <>
                {faqsList.data['Total-Page'] - 1 > filters.page ? (
                  <div className={scss.center_loader}>
                    <CircularProgressLoader />
                  </div>
                ) : null}
              </>
            )}
          >
            {scrollingData.map((ele: any, index: any) => (
              <QAS
                key={index}
                date={getDateAndTime(ele.createdAt)}
                question={ele.question}
                answer={ele.answer}
                status={ele.status}
                // eslint-disable-next-line no-underscore-dangle
                id={ele._id}
                handleOperations={handleOperations}
              />
            ))}
          </InfiniteScroll>
        )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  faqsList: state.faqs,
});
const mapDispatchToProps = (dispatch: any) => ({
  fetchFaqs: (payload: any) => dispatch(faqs(payload)),
});

const FQAS = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(FQASComponent);

export default FQAS;
