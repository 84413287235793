import React from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import searchBarApis from 'utils/searchBarApis';
import {
  Button,
  FieldDatePicker,
  FieldSelect,
  ValidationError,
  CircularProgressLoader,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  FieldSearchBar,
} from '../../components';
import { FormattedMessage, injectIntl } from '../../utils/reactIntl';
import Usertype from './RolesTypes.json';
import carretDown from '../../assets/svg/caretdown.svg';
import exp from '../../assets/svg/export.svg';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  handleFormSubmit: any,
  handleExport: any,
  loaderExport: any,
  intl: any,
  error: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const RoleManagementFilterForm: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    intl,
    error,
    handleExport,
    loaderExport,
  } = props;
  const formProps = useForm();
  const [showFilter, setShowFilter] = React.useState(true);
  const [toggle, setToggle] = React.useState(false);
  const handleReset = () => {
    formProps.reset({
      search: '',
      startDate: null,
      endDate: null,
      userType: '',
    });
  };
  return (
    <form
      className={commonStyles.filterForm}
      onSubmit={formProps.handleSubmit(handleFormSubmit)}
    >
      <div className={commonStyles.filter_container}>
        <div className={commonStyles.filterSearch}>
          <div>
            <div className={commonStyles.filter}>
              Filter
              <span
                className={commonStyles.closeBtn}
                role="presentation"
                onClick={() => setTimeout(() => {
                  setShowFilter((prevState) => !prevState);
                }, 200)}
              >
                {showFilter ? <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg> : <img src={carretDown} alt="down" />}
              </span>
            </div>
            <div className={commonStyles.fieldSet}>
              <FieldSearchBar
                searchURL={searchBarApis.rolesManagement}
                formProps={formProps}
                name="search"
                label="Search by Rolename"
                id="search"
              />
            </div>
          </div>
          <AccessControl
            permissionKey={PERMISSION_KEYS.ROLE_MANAGEMENT}
            accessType={PERMISSION_TYPES.EDIT}
          >
            <div className={commonStyles.userRightExport}>
              {!loaderExport
                ? (
                  <>
                    <span>
                      <img
                        src={exp}
                        alt="export"
                        role="presentation"
                        onClick={() => setToggle((prevState: boolean) => !prevState)}
                      />
                    </span>
                    {toggle && (
                      <ul className={commonStyles.userrigthToggle}>
                        <li
                          role="presentation"
                          onClick={() => handleExport('XLSX')}
                        >
                          Export as XLSX
                        </li>
                        <li
                          role="presentation"
                          onClick={() => handleExport('CSV')}
                        >
                          Export as CSV
                        </li>
                      </ul>
                    )}
                  </>
                ) : <CircularProgressLoader />}
            </div>
          </AccessControl>
        </div>
        {showFilter && (
          <div>
            <p>
              Added on
            </p>
            <div className={commonStyles.filterOptions}>
              <div className={commonStyles.datepickerField}>
                <FieldDatePicker
                  id="startDate"
                  name="startDate"
                  className="dateOfBirthInput"
                  label={intl.formatMessage({ id: 'RoleManagement.startDate.label' })}
                  formProps={formProps}
                  maxDate={formProps.watch('endDate') !== null
                    ? formProps.watch('endDate')
                    : moment().subtract(0, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className={commonStyles.datepickerField}>
                <FieldDatePicker
                  id="endDate"
                  name="endDate"
                  className="dateOfBirthInput"
                  label={intl.formatMessage({ id: 'RoleManagement.endDate.label' })}
                  formProps={formProps}
                  minDate={formProps.watch('startDate') !== null
                    ? formProps.watch('startDate')
                    : moment().subtract(118, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className={commonStyles.userType}>
                <FieldSelect
                  id="userType"
                  name="userType"
                  variant={false}
                  label={intl.formatMessage({ id: 'RoleManagement.status.label' })}
                  formProps={formProps}
                  options={Usertype}
                />
              </div>
              <div className={commonStyles.buttonWrapper}>
                <Button buttonType={ButtonTypes.submit} className={commonStyles.button}>
                  <FormattedMessage id="RoleManagement.apply.label" />
                </Button>
                <Button onClick={handleReset} className={commonStyles.button}>
                  <FormattedMessage id="RoleManagement.reset.label" />
                </Button>
              </div>
            </div>
            <ValidationError error={error} />
          </div>
        )}
      </div>
    </form>
  );
};

export default injectIntl(RoleManagementFilterForm);
