import React from 'react';
import classNames from 'classnames';
import {
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { ReactComponent as Dropdown } from 'assets/svg/Dropdown.svg';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import scss from './FieldSelect.module.scss';
import unselectedBorder from '../../assets/images/input-border-unselected.svg';
import selectedBorder from '../../assets/images/input-border-selected.svg';

const useStyles = makeStyles(() => createStyles({
  root: {
    '& .MuiInputBase-input': {
      color: '#ffffff',
      font: 'normal normal normal 14px/16px Poppins',
      fontSize: '14px',
      letterSpacing: '0.14px',
      paddingLeft: '12px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderImageSlice: '1',
      borderWidth: '1px',
      borderImage: `url(${unselectedBorder})`,
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderImage: 'linear-gradient( to right, rgb(255 0 0 / 65%) 100%, rgb(203 32 32) 50%, rgb(255 0 0 / 52%) -1% )',
      borderWidth: '1px',
      borderImageSlice: '1',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ffffff',
    },
  },
  formControl: {
    minWidth: '100%',
    '& .MuiFormLabel-root': {
      color: '#8A8A8A',
      letterSpacing: '0.12px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderImageSlice: '1',
      borderWidth: '1px',
      borderImage: `url(${selectedBorder})`,
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderImage: 'linear-gradient( to right, rgb(255 0 0 / 65%) 100%, rgb(203 32 32) 50%, rgb(255 0 0 / 52%) -1% )',
      borderWidth: '1px',
      borderImageSlice: '1',
    },
  },
  countryCodeSelect: {
    '&:before': {
      border: 'none',
    },
    '&:after': {
      border: 'none',
    },
    '&:not(.Mui-disabled):hover::before': {
      border: 'none',
    },
    '& .MuiSelect-select:focus': {
      background: 'none',
    },
  },
  iconSpacing: {
    top: 'calc(28%) !important',
  },
}));

interface Props {
  rootClassName?: string,
  className?: string,

  control: any,
  errors: any,
  fieldName: string,
  defaultValue?: string,
  id: string,
  label?: string,
  placeholder?: string,
  showError?: boolean,
  search?: boolean,
  searchProps?: any,

  required?: boolean,
  variant?: boolean,
  children?: any,
}

const FieldSelect = (props: Props) => {
  const MUIclasses = useStyles();
  const {
    rootClassName,
    className,
    fieldName,
    control,
    id,
    label,
    searchProps,
    defaultValue,
    placeholder,
    errors,
    children,
  } = props;

  const selectClasses = classNames(MUIclasses.root, scss.select, errors ? scss.fieldSelectError : null, className === 'countryCode' ? MUIclasses.countryCodeSelect : null);

  const selectProps = {
    className: selectClasses, id, inputProps: { 'aria-label': 'Without label' }, label,
  };

  const classes = classNames(rootClassName || scss.root, className ? scss[className] : null);
  return (
    <div className={classes}>
      <FormControl className={classNames(MUIclasses.formControl)}>
        <InputLabel id={id}>{label}</InputLabel>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={defaultValue ?? ''}
          render={({
            field,
          }) => (
            <Select
              // displayEmpty
              {...field}
              {...selectProps}
              {...searchProps}
              IconComponent={Dropdown}
              inputProps={{
                classes: {
                  root: MUIclasses.root,
                },
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              placeholder={placeholder}
              error={errors}
            >
              {children}

            </Select>
          )}
        />
      </FormControl>
    </div>
  );
};

FieldSelect.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  children: null,
  required: false,
  showError: true,
  search: false,
  searchProps: null,
  defaultValue: undefined,
  variant: false,
  placeholder: null,
};

export default FieldSelect;
