/* eslint-disable dot-notation */
import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  RewardPointBlocked,
  RewardPointSave,
} from 'components';
import { fetchCreditActivity } from 'actions';
import { TextField } from '@material-ui/core';
import blockIcon from 'assets/svg/block.svg';
import unblock from 'assets/svg/unblock.svg';
import saveIcon from 'assets/svg/save.svg';
import editIcon from 'assets/svg/edit.svg';
import avatcoin from 'assets/svg/avatcoin.svg';
import DataTable from '../Datatable';
import scss from './creditactivity.module.scss';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  creditactivity: any;
  fetchCredit: any;
}

const CreditActivity: React.FC<Props> = (props: Props) => {
  const {
    creditactivity,
    fetchCredit,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    type: 'credit',
  });
  const [fieldEditable, setFieldEditable] = React.useState<any>();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [creditValue, setCreditValue] = React.useState<any>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [save, setSave] = React.useState<any>({
    show: false,
    id: null,
  });
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });

  React.useEffect(() => {
    fetchCredit(filter);
  }, [fetchCredit, filter]);

  const handleBlockUnblock = (modalValue: any, id: string) => {
    switch (modalValue) {
      case 'blocked':
        setShowModal(true);
        setBlocked({
          ...blocked,
          show: true,
          id,
        });
        break;
      case 'unblocked':
        setShowModal(true);
        setUnBlocked({
          ...unblocked,
          show: true,
          id,
        });
        break;
      case 'save':
        setShowModal(true);
        setSave({
          ...save,
          show: true,
          id,
        });
        break;
      default:
        break;
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = creditactivity.data ? creditactivity.data['filterCount'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleModalClose = () => {
    setShowModal(false);
    setBlocked(false);
    setBlocked({ ...blocked, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
    setSave({ ...save, show: false, id: null });
  };

  const actionButtons = (action: any, idS: any, showEdit: any) => (
    <>
      {showEdit ? (
        <img
          src={editIcon}
          role="presentation"
          alt="save"
          width="35"
          title="Save"
          height="35"
          onClick={() => setFieldEditable({ id: idS })}
        />
      ) : (
        <img
          src={saveIcon}
          role="presentation"
          alt="edit"
          width="35"
          title="Edit"
          height="35"
          onClick={() => handleBlockUnblock('save', idS)}
        />
      )}
      {action === 'publish' || action === 'unblocked' ? (
        <img
          src={blockIcon}
          role="presentation"
          alt="block"
          width="35"
          title="Block"
          height="35"
          onClick={() => handleBlockUnblock('blocked', idS)}
        />
      ) : (
        <img
          src={unblock}
          role="presentation"
          alt="unblock"
          width="35"
          title="Unblock"
          height="35"
          onClick={() => handleBlockUnblock('unblocked', idS)}
        />
      )}
    </>
  );

  function createData(data: any, index: number): any {
    // eslint-disable-next-line no-underscore-dangle
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      name: data.name,
      credit: data.points > 0
        ? (
          fieldEditable !== undefined && fieldEditable.id === idS
            ? (
              <TextField
                variant="outlined"
                type="number"
                placeholder="Enter value"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                defaultValue={data.points}
                onChange={(e: any) => {
                  const values: any = creditValue.filter(
                    (ele: any) => ele.index !== idS,
                  );
                  setCreditValue(() => [
                    ...values,
                    {
                      index: idS,
                      value: e.target.value,
                    },
                  ]);
                }}
              />
            ) : (
              <div className={scss.imagewrapper}>
                <img
                  src={avatcoin}
                  alt="avatcoin"
                />
                <p>{data.points}</p>
              </div>
            )
        ) : (
          <TextField
            variant="outlined"
            type="number"
            placeholder="Enter value"
            InputProps={{
              inputProps: { min: 0 },
            }}
            onChange={(e: any) => {
              const values: any = creditValue.filter(
                (ele: any) => ele.index !== idS,
              );
              setCreditValue(() => [
                ...values,
                {
                  index: idS,
                  value: e.target.value,
                },
              ]);
            }}
          />
        ),
      action: data.points > 0
        ? (fieldEditable !== undefined && fieldEditable.id === idS
          ? actionButtons(data.status, idS, false)
          : actionButtons(data.status, idS, true))
        : actionButtons(data.status, idS, false),

    };
  }

  const rows: any = [];
  if (creditactivity.data !== null && creditactivity.data) {
    creditactivity.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const headCellsPostsCreated: any = [
    {
      id: 'sno',
      label: 'S.No',
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'credit',
      label: 'Credit',
    },
    {
      id: 'action',
      label: 'Posted On',
    },
  ];

  const handleSortDomesManagement = (order: any, orderBy: any) => {
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByParentCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'name') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    if (event.target['localName'] === 'input' && event.target['type'] !== 'number') {
      handleSelectedClick(event, name);
    }
  };

  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          save.show && (
            <RewardPointSave
              handleModalClose={handleModalClose}
              id={save.id}
              fetchCreditList={() => { setFilter({ ...filter, page: 0 }); setFieldEditable(undefined); }}
              creditValue={creditValue.filter((ele: any) => ele.index === save.id)[0].value}
            />
          )
        }
        {
          blocked.show && (
            <RewardPointBlocked
              handleModalClose={handleModalClose}
              fetchCreditList={() => { setFilter({ ...filter, page: 0 }); }}
              id={blocked.id}
              status="blocked"
            />
          )
        }
        {
          unblocked.show && (
            <RewardPointBlocked
              handleModalClose={handleModalClose}
              fetchCreditList={() => { setFilter({ ...filter, page: 0 }); }}
              id={unblocked.id}
              status="unblocked"
            />
          )
        }
      </Modal>
      <h2 className={scss.heading_reward}>
        Credit Activity(
        {count}
        )
      </h2>
      <div className={commonStyles.dataTableCover}>
        <DataTable
          headCells_={headCellsPostsCreated}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={creditactivity.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSortDomesManagement}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { creditactivity } = state;
  return {
    creditactivity,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchCredit: (payload: any) => dispatch(
    fetchCreditActivity(payload),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditActivity);
