import classNames from 'classnames';
import { PriceManagement } from 'components';
import React from 'react';
import { connect } from 'react-redux';
import scss from './settings.module.scss';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

const Settings: React.FC = () => (
  <>
    <div className={classNames(commonStyles.cardBg, scss.container)}>
      <PriceManagement />
    </div>
  </>
);

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
