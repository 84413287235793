import React from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
  FieldTextInput,
  Button,
  FieldDatePicker,
  ValidationError,
  FieldSelect,
} from '../../components';
import { injectIntl } from '../../utils/reactIntl';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import carretDown from '../../assets/svg/caretdown.svg';
import filters from './FAQFilters.json';

interface Props {
  handleFormSubmit: any,
  intl: any,
  error: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const FAQSFiltersForm: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    intl,
    error,
  } = props;
  const formProps = useForm();
  const [showFilter, setShowFilter] = React.useState(true);
  const handleReset = () => {
    formProps.setValue('search', '');
    formProps.setValue('startDate', null);
    formProps.setValue('endDate', null);
    formProps.setValue('status', '');
  };
  return (
    <form
      className={commonStyles.filterForm}
      onSubmit={formProps.handleSubmit(handleFormSubmit)}
    >
      <div className={commonStyles.filter_container}>
        <div className={commonStyles.filterSearch}>
          <div className={commonStyles.filter}>
            Filter
            <span
              className={commonStyles.closeBtn}
              role="presentation"
              onClick={() => setTimeout(() => {
                setShowFilter((prevState) => !prevState);
              }, 200)}
            >
              {showFilter ? <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg> : <img src={carretDown} alt="down" />}
            </span>
          </div>
          <div className={commonStyles.fieldSet}>
            <FieldTextInput
              id="search"
              name="search"
              formName="UserManagementFilter"
              type="text"
              formProps={formProps}
              label="Search by Username"
            />
          </div>
        </div>
        {showFilter && (
          <div>
            <p>
              Added on
            </p>
            <div className={commonStyles.filterOptions}>
              <div className={commonStyles.datepickerField}>
                <FieldDatePicker
                  id="startDate"
                  name="startDate"
                  className="dateOfBirthInput"
                  label="*From Date"
                  formProps={formProps}
                  maxDate={formProps.watch('endDate') !== null ? formProps.watch('endDate') : moment().subtract(0, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className={commonStyles.datepickerField}>
                <FieldDatePicker
                  id="endDate"
                  name="endDate"
                  className="dateOfBirthInput"
                  label="*To Date"
                  formProps={formProps}
                  minDate={formProps.watch('startDate') !== null ? formProps.watch('startDate') : moment().subtract(118, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className={commonStyles.userType}>
                <FieldSelect
                  id="status"
                  name="status"
                  variant={false}
                  label="Status"
                  defaultValue=""
                  formProps={formProps}
                  options={filters}
                />
              </div>
              <div className={commonStyles.buttonWrapper}>
                <Button buttonType={ButtonTypes.submit} className={commonStyles.button}>
                  Apply
                </Button>
                <Button buttonType={ButtonTypes.reset} onClick={handleReset} className={commonStyles.button}>
                  Reset
                </Button>
              </div>
            </div>
            <ValidationError error={error} />
          </div>
        )}
      </div>
    </form>
  );
};

export default injectIntl(FAQSFiltersForm);
