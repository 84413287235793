/* eslint-disable dot-notation */
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dateFormatting } from 'utils/dateMethods';
import {
  ChannelManagementBlocked, hasAccess, Modal, PERMISSION_KEYS, PERMISSION_TYPES, ToastBar,
} from 'components';
import { ChannelManagementService } from 'services';
import { exportToCSV, exportToExcel } from 'utils/exportToExcel';
import DataTable from '../Datatable';
import scss from './channelmanagement.module.scss';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import { getChannelList } from '../../actions';
import ChannelManagementFilter from '../../forms/ChannelManagementFilter';
import blockIcon from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';

interface Props {
  channellist: any;
  fetchChannelList: any;
}

const ChannelManagement: React.FC<Props> = (props: Props) => {
  const {
    channellist,
    fetchChannelList,
  } = props;
  const history = useHistory();
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    startDate: '',
    endDate: '',
    userType: '',
    search: '',
    domes: '',
    isSortByCreated: '',
    isSortByCategoryName: '',
    isSortByChannelName: '',
    isSortByUserName: '',
  });
  const [domes, setDomes] = React.useState<any>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [loaderExport, setLoaderExport] = React.useState(false);
  React.useEffect(() => {
    fetchChannelList(filter);
  }, [fetchChannelList, filter]);
  React.useEffect(() => {
    ChannelManagementService.getDomes().then((data) => {
      const domesData: any = [];
      if (data.data.data !== undefined) {
        data.data.data.forEach((ele: any) => {
          // eslint-disable-next-line no-underscore-dangle
          domesData.push({ key: ele._id, label: ele.name });
        });
      }
      setDomes(domesData);
    });
  }, []);
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      default:
        break;
    }
  };
  function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = channellist.data ? channellist.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitChannelFilter = (data: any) => {
    setFilter({
      ...filter,
      startDate: data.startDate ? data.startDate.utc() : '',
      endDate: data.endDate ? data.endDate.utc() : '',
      search: data.search.label,
      domes: data.domes,
      userType: data.userType,
      page: 0,
    });
  };
  const handleModalClose = () => {
    setShowModal(false);
    setBlocked({ ...blocked, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };
  const actionButtons = (action: any, idS: any) => (
    <>
      {action === 'active' ? (
        <img
          src={blockIcon}
          role="presentation"
          alt="block"
          width="35"
          title="Block"
          height="35"
          onClick={() => handleBlockUnblock('blocked', idS)}
        />
      ) : (
        <img
          src={unblock}
          role="presentation"
          alt="unblock"
          width="35"
          title="Unblock"
          height="35"
          onClick={() => handleBlockUnblock('unblocked', idS)}
        />
      )}
    </>
  );

  function createData(data: any, index: number): any {
    // eslint-disable-next-line no-underscore-dangle
    const idS = data._id;
    if (hasAccess(PERMISSION_KEYS.CHANNEL_MANAGEMENT, PERMISSION_TYPES.EDIT)) {
      return {
        sno: filter.page * filter.rowsPerPage + index + 1,
        id: idS,
        channelName: data.channelName,
        avatar: <img src={data.channelIconImage} alt={data.channelName} width={50} height={50} />,
        username: data.user.username,
        linktowebsite: data.link,
        domes: data.channelName,
        pricingplan: data.subscriptionType,
        subscribercount: data.subscriberCount,
        channelType: capitalize(data.status),
        postCount: data.postCount,
        createdAt: dateFormatting(data.createdAt),
        action: actionButtons(data.status, idS),
      };
    }
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      channelName: data.channelName,
      avatar: <img src={data.channelIconImage} alt={data.channelName} width={50} height={50} />,
      username: data.user.username,
      linktowebsite: data.link,
      domes: data.channelName,
      pricingplan: data.subscriptionType,
      subscribercount: data.subscriberCount,
      channelType: capitalize(data.status),
      postCount: data.postCount,
      createdAt: dateFormatting(data.createdAt),
    };
  }

  const rows: any = [];
  if (channellist.data !== null && channellist.data) {
    channellist.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const headCells: any = hasAccess(PERMISSION_KEYS.CHANNEL_MANAGEMENT, PERMISSION_TYPES.EDIT) ? [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'channelName', label: 'Name', sort: true,
    },
    {
      id: 'avatar', label: 'Avatar', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: true,
    },
    {
      id: 'linktowebsite', label: 'Link to Website', sort: false,
    },
    {
      id: 'domes', label: 'Domes', sort: true,
    },
    {
      id: 'pricingplan', label: 'Pricing Plan', sort: false,
    },
    {
      id: 'subscribercount', label: 'No. of Subscribers', sort: false,
    },
    {
      id: 'channelType', label: 'Channel Type', sort: false,
    },
    {
      id: 'postCount', label: 'No. of Post', sort: false,
    },
    {
      id: 'createdAt', label: 'Created On', sort: true,
    },
    {
      id: 'action', label: 'Action', sort: false,
    },
  ] : [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'channelName', label: 'Name', sort: true,
    },
    {
      id: 'avatar', label: 'Avatar', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: true,
    },
    {
      id: 'linktowebsite', label: 'Link to Website', sort: false,
    },
    {
      id: 'domes', label: 'Domes', sort: true,
    },
    {
      id: 'pricingplan', label: 'Pricing Plan', sort: false,
    },
    {
      id: 'subscribercount', label: 'No. of Subscribers', sort: false,
    },
    {
      id: 'channelType', label: 'Type', sort: false,
    },
    {
      id: 'postCount', label: 'No. of Post', sort: false,
    },
    {
      id: 'createdAt', label: 'Created On', sort: true,
    },
  ];

  const handleSortChannelManagement = (order: any, orderBy: any) => {
    if (orderBy === 'channelName') {
      setFilter({
        ...filter,
        page: 0,
        isSortByChannelName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'username') {
      setFilter({
        ...filter,
        page: 0,
        isSortByUserName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            channelName: ele.channelName,
            user: ele.username,
            domes: ele.domes,
            link: ele.linktowebsite,
            postCount: ele.postCount,
            planPricing: ele.pricingplan,
            status: ele.channelType,
            subscriberCount: ele.subscribercount,
            createdAt: ele.createdAt,
          });
        });
        exportToExcel(selectedRowDataModified, 'ChannelManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        ChannelManagementService.exportUserListToMail({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            channelName: ele.channelName,
            user: ele.username,
            domes: ele.domes,
            link: ele.linktowebsite,
            postCount: ele.postCount,
            planPricing: ele.pricingplan,
            status: ele.channelType,
            subscriberCount: ele.subscribercount,
            createdAt: ele.createdAt,
          });
        });
        exportToCSV(selectedRowDataModified, 'ChannelManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        ChannelManagementService.exportUserListToMail({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        });
      }
    }
  };
  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    if (event.target['localName'] === 'img' && event.target['alt'] === 'block') {
      handleBlockUnblock('blocked', name.id);
    } else if (event.target['localName'] === 'img' && event.target['alt'] === 'unblock') {
      handleBlockUnblock('unblocked', name.id);
    } else if (event.target['localName'] === 'input') {
      handleSelectedClick(event, name);
    } else history.push(`/channelmanagement/channeldetails/${name.id}`);
  };
  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          blocked.show && (
            <ChannelManagementBlocked
              handleModalClose={handleModalClose}
              fetchChannelList={() => { setFilter({ ...filter, page: 0 }); }}
              id={blocked.id}
              status="blocked"
            />
          )
        }
        {
          unblocked.show && (
            <ChannelManagementBlocked
              handleModalClose={handleModalClose}
              fetchChannelList={() => { setFilter({ ...filter, page: 0 }); }}
              id={unblocked.id}
              status="unblocked"
            />
          )
        }
      </Modal>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div className={scss.container}>
        <ChannelManagementFilter
          handleFormSubmit={handleSubmitChannelFilter}
          domes={domes}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className={commonStyles.dataTableCover}>
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={channellist.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSortChannelManagement}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { channelmanagement } = state;
  return {
    channellist: channelmanagement,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchChannelList: (payload: any) => dispatch(getChannelList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelManagement);
