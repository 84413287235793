import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'utils/reactIntl';
import {
  Button,
  FieldFileInput,
  FieldSelect,
  FieldTextInput,
  CircularProgressLoader,
  ValidationError,
} from 'components';
import {
  ChannelManagementService,
  SubDomesManagementService,
} from 'services';
import classNames from 'classnames';
import scss from './EditSubDomesForm.module.scss';
import information from '../../assets/svg/info.svg';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  handleSubmit: any;
  handleSaveAsDraftPut: any;
  loader: any;
  error: string;
  id: string;
}

const EditSubDomesForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    intl,
    loader,
    id,
    error,
    handleSaveAsDraftPut,
  } = props;
  const [domes, setDomes] = React.useState([]);
  const [initialLoader, setInitialLoader] = React.useState(true);
  const editSubDomesSchema = yup.object().shape({
    domes: yup.string().required('Please fill all the required fields'),
    domeName: yup.string()
      .required('Please fill all required fields'),
    description: yup.string()
      .required('Please fill all required fields')
      .max(250, 'Please enter characters less than 250 characters for description'),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(editSubDomesSchema),
  });

  React.useEffect(() => {
    ChannelManagementService.getDomes().then((data) => {
      const domesData: any = [];
      if (data.data.data !== undefined) {
        data.data.data.forEach((ele: any) => {
          // eslint-disable-next-line no-underscore-dangle
          domesData.push({ key: ele._id, label: ele.name });
        });
      }
      setDomes(domesData);
    });
    SubDomesManagementService.getDetails(id).then((data: any) => {
      formProps.reset({
        // eslint-disable-next-line no-underscore-dangle
        domes: data.data.data.parentId._id,
        domeName: data.data.data.name,
        description: data.data.data.description,
        selectedIcon: data.data.data.selectedImage,
        unselectedIcon: data.data.data.nonSelectedImage,
      });
      setInitialLoader(false);
    });
  }, [formProps, id]);

  return (
    <>
      {initialLoader ? (
        <div>
          <CircularProgressLoader />
        </div>
      )
        : (
          <div className={classNames(commonStyles.cardBg, scss.container)}>
            <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleSubmit)}>
              <h2 className={scss.sign_text}>
                {intl.formatMessage({ id: 'SubDomesManagementFilter.title' })}
              </h2>
              <div className="flex_row">
                <div className="flex_col_sm_6">
                  <div className={scss.userType}>
                    <FieldSelect
                      id="domes"
                      name="domes"
                      label="Domes"
                      variant={false}
                      formProps={formProps}
                      options={domes}
                    />
                  </div>
                </div>
                <div className="flex_col_sm_6">
                  <FieldTextInput
                    id="domeName"
                    name="domeName"
                    formName="editSubDomes"
                    className="textInput"
                    type="text"
                    formProps={formProps}
                    label="*Sub Dome Name"
                  />
                </div>
                <div className="flex_col_sm_12">
                  <div>
                    <FieldTextInput
                      id="description"
                      name="description"
                      formName="editSubDomes"
                      className="textInput"
                      type="textarea"
                      formProps={formProps}
                      label="*Description(250 characters)"
                    />
                  </div>
                </div>
              </div>

              <h2 className={scss.sign_text}>Upload icon for this dome</h2>
              <div className={scss.profileWrapperimg}>
                <div className={scss.selection_box}>
                  <h4>Selected icon</h4>
                  <FieldFileInput
                    id="selectedIcon"
                    name="selectedIcon"
                    className="textInput"
                    formProps={formProps}
                    rootClassName={scss.input_upload}
                  />
                </div>
                <div className={scss.selection_box}>
                  <h4>Unselected icon</h4>
                  <FieldFileInput
                    id="unselectedIcon"
                    name="unselectedIcon"
                    className="textInput"
                    formProps={formProps}
                    rootClassName={scss.input_upload}
                  />
                </div>
              </div>
              <p className={scss.informationUpload}>
                <img src={information} alt="information" />
                The size of file should be less than or equal to 50 MB and
                should be in jpeg and png format
              </p>
              {Object.keys(formProps.formState.errors).length > 0
                && (
                  <ValidationError
                    className={scss.validation_error}
                    error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
                  />
                )}
              {
                Object.keys(formProps.formState.errors).length === 0
                && <ValidationError error={error} />
              }
              {loader ? (
                <div className={commonStyles.savingLoader}>
                  {' '}
                  <CircularProgressLoader />
                </div>
              ) : (
                <>
                  <div className={scss.buttonWrapper}>
                    <Button
                      onClick={(e: any) => handleSaveAsDraftPut(e, formProps.getValues())}
                    >
                      Save as Draft
                    </Button>
                    <Button
                      buttonType={ButtonTypes.submit}
                    >
                      Publish
                    </Button>
                  </div>
                </>
              )}
            </form>
          </div>
        )}
    </>
  );
};

export default injectIntl(EditSubDomesForm);
