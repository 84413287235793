import { Button } from 'components';
import React from 'react';
import { ChannelInfoService } from 'services';
import warning from '../../assets/svg/warning.svg';
import scss from './postcreateddelete.module.scss';

interface Props {
  handleModalClose: any;
  id: any;
  fetchPostList: any;
}

const SubDomesManagementDelete: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    fetchPostList,
  } = props;

  const [deleted, setDeleted] = React.useState(false);
  const deleteDomesService = () => {
    ChannelInfoService.deletePostApi(id).then(() => {
      setDeleted(true);
    });
  };

  return (
    <>
      {!deleted ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            Delete Post
          </h2>
          <p>
            Are you sure you want to delete this post?
          </p>
          <div className={scss.modal_footer}>
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={deleteDomesService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Post deleted successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchPostList();
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default SubDomesManagementDelete;
