/* eslint-disable max-classes-per-file */

class AuthDataModal {
    isAuthenticated = false;

authScopes: any = [];

// auth info

    authInfoLoaded = false;

    // login

    loginError = null;

    loginInProgress = false;

    // logout

    logoutError = null;

    logoutInProgress = false;

    loginInfoData = null;

    // forgotpass

    forgotpassInprogress = false;

    forgotpassError = null;

    forgotpassSucess = false;
}

class RoutingModal {
    currentLocation = null;

    currentCanonicalUrl = null;
}

class AvatarModal {
    data: any = null;

    avatarError: any = null;

    loadingAvatar = false;
}

class Usermanagement {
    data: any= null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ChannelManagement {
    data: any=null;

    loadingInProgress: any = null;

    loadingSucess: any = null;

    loadingError: any = null;
}

class ChannelInfo {
    data: any = null;

    loadingChannelInfoProgress: any = false;

    loadingChannelInfoSucess: any = false;

    loadingChannelInfoError: any = false;
}

class UserInfo {
    data: any= null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class UserProfileInfo {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class DomesManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class SubDomesManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ContentManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}
class FAQSManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class RoleManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PostCreated {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class LoginDetails {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class SubAdminManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class CreditActivityDetails {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class DebitActivityDetails {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ReportedUserManagement {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class NotesDetails {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class UserReportedUser {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PostReportedUser {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class ChannelReported {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class NotificationDetails {
    data: any = null;

    loadingInProgress: any = null;

    loadingSuccess: any = null;

    loadingError: any = null;
}

class PriceManagement {
    data: any = null;

    loadingInProgress: any = true;

    loadingSuccess: any = null;

    loadingError: any = null;
}

export {
  AvatarModal,
  AuthDataModal,
  RoutingModal,
  Usermanagement,
  UserInfo,
  ChannelManagement,
  ChannelInfo,
  UserProfileInfo,
  DomesManagement,
  SubDomesManagement,
  ContentManagement,
  FAQSManagement,
  RoleManagement,
  PostCreated,
  LoginDetails,
  SubAdminManagement,
  CreditActivityDetails,
  DebitActivityDetails,
  ReportedUserManagement,
  NotesDetails,
  UserReportedUser,
  PostReportedUser,
  ChannelReported,
  NotificationDetails,
  PriceManagement,
};
