import { combineReducers } from 'redux';
import userreportdetail from './usereporteduser';
import rolesmanagement from './rolesmanagement';
import routing from './routing';
import auth from './auth';
import usermanagement from './usermanagement';
import channelmanagement from './channelmanagement';
import userinfo from './userInfo';
import channelinfo from './channelInfo';
import updateProfile from './updateProfile';
import domesmanagement from './domesmanagement';
import subdomesmanagement from './subdomesmanagement';
import contentmanagement from './contentmanagement';
import faqs from './faqs';
import postcreated from './postcreated';
import logindetails from './logindetails';
import subadminmanagement from './subadminmanagement';
import creditactivity from './creditactivity';
import debitactivity from './debitactivity';
import reportedusermanagement from './reportedusermanagement';
import notesdetails from './notesdetails';
import postreportdetail from './postreportdetail';
import channelreported from './channelreported';
import notificationdetails from './notificationdetail';
import pricemanagement from './pricemanagement';

const rootReducer = combineReducers({
  routing,
  auth,
  usermanagement,
  userinfo,
  channelmanagement,
  channelinfo,
  updateProfile,
  domesmanagement,
  subdomesmanagement,
  contentmanagement,
  faqs,
  rolesmanagement,
  postcreated,
  logindetails,
  subadminmanagement,
  creditactivity,
  debitactivity,
  reportedusermanagement,
  notesdetails,
  userreportdetail,
  postreportdetail,
  channelreported,
  notificationdetails,
  pricemanagement,
});

const appReducer = (state: any, action: any) => rootReducer(state, action);

export default appReducer;
