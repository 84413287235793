import api from '../api';

export class ContentManagementService {
  static async getContent() {
    return api.getApiCall('/api/v1/admins/content-managements');
  }

  static async updatePrivacyPolicy(payload: any) {
    return api.putApiCall('/api/v1/admins/content-managements', payload);
  }

  static async getFAQS(payload: any) {
    const {
      page,
      limit,
      startDate,
      endDate,
      search,
    } = payload;
    return api.getApiCall(`/api/v1/admins/faqs?limit=${limit}&pageNo=${page}${startDate !== '' && startDate !== null ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== null ? `&endDate=${endDate}` : ''}${search !== '' && search !== null ? `&search=${search}` : ''}`);
  }

  static async saveToDraftFAQ(payload: any) {
    return api.postApiCall('/api/v1/admins/faqs', { ...payload, status: 'pending' });
  }

  static async addToDraftFAQ(payload: any) {
    return api.postApiCall('/api/v1/admins/faqs', { ...payload });
  }

  static async deleteFAQ(payload: any) {
    return api.deleteApiCall('/api/v1/admins/faqs', payload);
  }

  static async detailsFAQ(payload: any) {
    return api.getApiCall(`/api/v1/admins/faqs/${payload}`);
  }
}
