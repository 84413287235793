/* eslint-disable dot-notation */
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserInfoService, UserManagementService } from 'services';
import { exportToCSV, exportToExcel } from 'utils/exportToExcel';
import DataTable from '../Datatable';
import scss from './usermanagement.module.scss';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import UserManagementFilter from '../../forms/UserManagementFilter';
import {
  Button, hasAccess, Modal, PERMISSION_KEYS, PERMISSION_TYPES, ToastBar,
} from '..';
import { getUserList } from '../../actions';
import avatus from '../../assets/svg/image-placeholder.png';
import block from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';
import { dateFormatting } from '../../utils/dateMethods';
import warning from '../../assets/svg/warning.svg';

interface Props {
  userlist: any;
  fetchUserList: any;
}

const Usermanagement: React.FC<Props> = (props: Props) => {
  const {
    userlist,
    fetchUserList,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    startDate: '',
    endDate: '',
    userType: '',
    search: '',
    domes: '',
    isSortByUserName: '',
    isSortByRegister: '',
  });
  const history = useHistory();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [blocked, setBlocked] = React.useState(false);
  const [unBlocked, setUnBlocked] = React.useState(false);
  const [blockedUserId, setBlockedUserId] = React.useState('');
  const [blockedConfirm, setBlockedConfirm] = React.useState(false);
  const [unBlockedConfirm, setUnBlockedConfirm] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  React.useEffect(() => {
    fetchUserList(filter);
  }, [fetchUserList, filter]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = userlist.data ? userlist.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const handleBlockUnblock = (bloc: boolean, id: string) => {
    setShowModal(true);
    if (bloc) {
      setUnBlocked(true);
    } else setBlocked(true);
    setBlockedUserId(id);
  };

  const handleSubmitUserFilter = (data: any) => {
    setFilter({
      ...filter,
      startDate: data.startDate ? data.startDate.utc() : '',
      endDate: data.endDate ? data.endDate.utc() : '',
      search: data.search.label,
      userType: data.userType,
      page: 0,
    });
  };
  function createData(data: any, index: number): any {
    // eslint-disable-next-line no-underscore-dangle
    const id = data._id;
    return hasAccess(PERMISSION_KEYS.USER_MANAGEMENT, PERMISSION_TYPES.EDIT) ? {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id,
      username: data.username,
      avatar: <img src={avatus} alt="x" />,
      name: data.fullNameFlag ? 'Yes' : 'No',
      email: data.emailFlag ? 'Yes' : 'No',
      phoneFlag: data.phoneFlag ? 'Yes' : 'No',
      dob: data.ageFlag ? 'Yes' : 'No',
      gender: data.genderFlag ? 'Yes' : 'No',
      nationality: data.nationalityFlag ? 'Yes' : 'No',
      usertype: data.status === 'blocked' ? 'Blocked' : data.registerationStatus === 'completed' ? 'Active' : 'Pending',
      regon: dateFormatting(data.createdAt),
      action: data.status !== 'blocked' ? (
        <img
          src={block}
          role="presentation"
          alt="block"
          title="Block"
          width="35"
          height="35"
          onClick={() => handleBlockUnblock(false, id)}
        />
      ) : (
        <img
          src={unblock}
          role="presentation"
          alt="unblock"
          title="Unblock"
          width="35"
          height="35"
          onClick={() => handleBlockUnblock(true, id)}
        />
      ),
    } : {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id,
      username: data.username,
      avatar: <img src={avatus} alt="x" />,
      name: data.fullNameFlag ? 'Yes' : 'No',
      email: data.emailFlag ? 'Yes' : 'No',
      phoneFlag: data.phoneFlag ? 'Yes' : 'No',
      dob: data.ageFlag ? 'Yes' : 'No',
      gender: data.genderFlag ? 'Yes' : 'No',
      nationality: data.nationalityFlag ? 'Yes' : 'No',
      usertype: data.status === 'blocked' ? 'Blocked'
        : data.registerationStatus === 'completed'
          ? 'Active' : 'Pending',
      regon: dateFormatting(data.createdAt),
    };
  }
  const rows: any = [];
  if (userlist.data !== null && userlist.data) {
    userlist.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };
  const headCells: any = hasAccess(PERMISSION_KEYS.USER_MANAGEMENT, PERMISSION_TYPES.EDIT) ? [{
    id: 'sno', sort: false, disablePadding: true, label: 'S.No',
  },
  {
    id: 'username', sort: true, disablePadding: false, label: 'Username',
  },
  {
    id: 'avatar', sort: false, disablePadding: false, label: 'Avatar',
  },
  {
    id: 'name', sort: false, disablePadding: false, label: 'Name',
  },
  {
    id: 'email', sort: false, disablePadding: false, label: 'Email',
  },
  {
    id: 'phone', sort: false, disablePadding: false, label: 'Phone',
  },
  {
    id: 'dob', sort: false, disablePadding: false, label: 'DOB',
  },
  {
    id: 'gender', sort: false, disablePadding: false, label: 'Gender',
  },
  {
    id: 'nationality', sort: false, disablePadding: false, label: 'Nationality',
  },
  {
    id: 'usertype', sort: false, disablePadding: false, label: 'User Type',
  },
  {
    id: 'regon', sort: true, disablePadding: false, label: 'Registered On',
  },
  {
    id: 'action', sort: false, disablePadding: false, label: 'Action',
  },
  ] : [{
    id: 'sno', sort: false, disablePadding: true, label: 'S.No',
  },
  {
    id: 'username', sort: true, disablePadding: false, label: 'Username',
  },
  {
    id: 'avatar', sort: false, disablePadding: false, label: 'Avatar',
  },
  {
    id: 'name', sort: false, disablePadding: false, label: 'Name',
  },
  {
    id: 'email', sort: false, disablePadding: false, label: 'Email',
  },
  {
    id: 'phone', sort: false, disablePadding: false, label: 'Phone',
  },
  {
    id: 'dob', sort: false, disablePadding: false, label: 'DOB',
  },
  {
    id: 'gender', sort: false, disablePadding: false, label: 'Gender',
  },
  {
    id: 'nationality', sort: false, disablePadding: false, label: 'Nationality',
  },
  {
    id: 'usertype', sort: false, disablePadding: false, label: 'User Type',
  },
  {
    id: 'regon', sort: true, disablePadding: false, label: 'Registered On',
  },
  ];
  const handleCallBlockApi = (active: boolean) => {
    UserInfoService.blockUser({
      id: blockedUserId,
      status: active ? 'unblocked' : 'blocked',
    }).then(() => {
      if (active) {
        setUnBlocked(false);
        setUnBlockedConfirm(true);
      } else {
        setBlocked(false);
        setBlockedConfirm(true);
      }
    });
  };
  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    if (event.target['localName'] === 'img' && event.target['alt'] === 'block') {
      handleBlockUnblock(false, name.id);
    } else if (event.target['localName'] === 'img' && event.target['alt'] === 'unblock') {
      handleBlockUnblock(true, name.id);
    } else if (event.target['localName'] === 'input') {
      handleSelectedClick(event, name);
    } else history.push(`/usermanagement/userdetails/${name.id}`);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setBlockedConfirm(false);
    setBlocked(false);
    setUnBlocked(false);
    setUnBlockedConfirm(false);
  };
  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'username') {
      setFilter({ ...filter, page: 0, isSortByUserName: order === 'asc' ? '1' : '-1' });
    }
    if (orderBy === 'regon') {
      setFilter({ ...filter, page: 0, isSortByRegister: order === 'asc' ? '1' : '-1' });
    }
  };
  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            dob: ele.dob,
            email: ele.email,
            gender: ele.gender,
            id: ele.id,
            name: ele.name,
            nationality: ele.nationality,
            phoneFlag: ele.phoneFlag,
            regon: ele.regon,
            sno: ele.sno,
            username: ele.username,
            usertype: ele.usertype,
          });
        });
        exportToExcel(selectedRowDataModified, 'UserManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        UserManagementService
          .exportUserListToMail({ ...filter, type: 'xlsx' })
          .then(() => {
            setLoaderExport(false);
            setNotification({
              ...notification,
              show: true,
              message: 'Excel has been sent on mail.',
            });
          });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            dob: ele.dob,
            email: ele.email,
            gender: ele.gender,
            id: ele.id,
            name: ele.name,
            nationality: ele.nationality,
            phoneFlag: ele.phoneFlag,
            regon: ele.regon,
            sno: ele.sno,
            username: ele.username,
            usertype: ele.usertype,
          });
        });
        exportToCSV(selectedRowDataModified, 'UserManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        UserManagementService
          .exportUserListToMail({ ...filter, type: 'csv' })
          .then(() => {
            setLoaderExport(false);
            setNotification({
              ...notification,
              show: true,
              message: 'CSV has been sent on mail.',
            });
          });
      }
    }
  };
  const handleResetSorts = () => {
    setFilter({
      ...filter,
      isSortByUserName: '',
      isSortByRegister: '',
    });
  };

  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {blocked && (
          <div>
            <img src={warning} alt="warning" />
            <h2>Block User </h2>
            <p>Are you sure you want to block this user? </p>
            <div className={scss.modal_footer}>
              <Button onClick={handleModalClose}>No</Button>
              <Button onClick={() => handleCallBlockApi(false)}>Yes</Button>
            </div>
          </div>
        )}
        {unBlocked && (
          <div>
            <img src={warning} alt="warning" />
            <h2>Unblock User </h2>
            <p>Are you sure you want to unblock this user? </p>
            <div className={scss.modal_footer}>
              <Button onClick={handleModalClose}>No</Button>
              <Button onClick={() => handleCallBlockApi(true)}>Yes</Button>
            </div>
          </div>
        )}
        {blockedConfirm && (
          <div>
            <h3>User blocked sucessfully !</h3>
            <div className={scss.modal_footer}>
              <Button onClick={() => {
                handleModalClose();
                fetchUserList(filter);
              }}
              >
                Ok
              </Button>
            </div>
          </div>
        )}
        {unBlockedConfirm && (
          <div>
            <h3>User Unblocked sucessfully !!</h3>
            <div className={scss.modal_footer}>
              <Button onClick={() => {
                handleModalClose();
                fetchUserList(filter);
              }}
              >
                Ok

              </Button>
            </div>
          </div>
        )}
      </Modal>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div className={scss.container}>
        <UserManagementFilter
          handleFormSubmit={handleSubmitUserFilter}
          handleExport={handleExport}
          handleResetSorts={handleResetSorts}
          loaderExport={loaderExport}
        />
        <div className={commonStyles.dataTableCover}>
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            handleChangePage={handleChangePage}
            setSelectedRows={setSelectedRows}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loading={userlist.loadingInProgress}
            handleSort={handleSort}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { usermanagement } = state;
  return {
    userlist: usermanagement,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserList: (payload: any) => dispatch(getUserList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Usermanagement);
