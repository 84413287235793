import { Button, ValidationError } from 'components';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentManagementService } from 'services';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import scss from './managefaqs.module.scss';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface ParamTypes {
  id: any;
}

const ManageFAQsPageComponent: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const [QAs, setQAs] = React.useState();
  if (id !== 'addFAQS') {
    ContentManagementService.detailsFAQ(id).then((res: any) => {
      setQAs(res.data.data);
    });
  }
  const [answerState, setAnswerState] = React.useState(id === 'add'
    ? EditorState.createEmpty() : EditorState.createEmpty());
  const [questionState, setQuestionState] = React.useState(id === 'add'
    ? EditorState.createEmpty() : EditorState.createEmpty());
  const [error, setError] = React.useState('');

  const history = useHistory();
  enum ButtonTypes {
    'button',
    'submit',
    'reset',
    undefined
  }

  return (
    <>
      <div className={classNames(commonStyles.cardBg, scss.container)}>
        <h2>Question</h2>
        <Editor
          editorState={questionState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(e) => setQuestionState(e)}
          toolbar={{
            options: ['inline', 'textAlign'],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic', 'underline'],
            },
            textAlign: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['left', 'center', 'right', 'justify'],
            },
          }}
        />
        <h2>Answer</h2>
        <Editor
          editorState={answerState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(e) => setAnswerState(e)}
          toolbar={{
            options: ['inline', 'textAlign'],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic', 'underline'],
            },
            textAlign: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['left', 'center', 'right', 'justify'],
            },
          }}
        />
        {error.length > 0 && <ValidationError error={error} />}
        <div className={scss.buttonWrapper}>
          <Button
            buttonType={ButtonTypes.button}
            onClick={() => {
              if (answerState.getCurrentContent().getPlainText().length === 0
                || questionState.getCurrentContent().getPlainText().length === 0) {
                setError('Please fill all fields.');
              } else {
                ContentManagementService.addToDraftFAQ(
                  {
                    question: stateToHTML(questionState.getCurrentContent()),
                    answer: stateToHTML(answerState.getCurrentContent()),
                    status: 'unblocked',
                  },
                ).then(() => {
                  setError('');
                  history.push('/contentmanagement?active=FAQ');
                });
              }
            }}
          >
            Publish
          </Button>
          <Button
            buttonType={ButtonTypes.button}
            onClick={() => {
              if (answerState.getCurrentContent().getPlainText().length === 0
                || questionState.getCurrentContent().getPlainText().length === 0) {
                setError('Please fill all fields.');
              } else {
                ContentManagementService.addToDraftFAQ(
                  {
                    question: stateToHTML(questionState.getCurrentContent()),
                    answer: stateToHTML(answerState.getCurrentContent()),
                    status: 'draft',
                  },
                ).then(() => {
                  setError('');
                  history.push('/contentmanagement?active=FAQ');
                });
              }
            }}
          >
            Save as Draft
          </Button>

        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  routing: state.routing,
});
const mapDispatchToProps = () => ({
});

const ManageFAQS = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ManageFAQsPageComponent);

export default ManageFAQS;
