import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import {
  withRouter,
  // Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import config from '../../config.js';
import { FormattedMessage, injectIntl } from '../../utils/reactIntl';
import {
  Login,
  Page,
  NamedRedirect,
  ForgotPassword,
  ResetPasswordForm,
} from '../../components';
import css from './Auth.module.scss';
import {
  changeSuccessPass,
  forgotPassword,
  loginRequest,
} from '../../actions/auth';
import { AuthService } from '../../services/index';
import { aesEncrypted } from '../../utils/aesEncrypt';
import avtausLogo from '../../assets/images/avatus_logo.svg';

interface Props {
  isAuth: boolean,
  scrollingDisabled: boolean,
  // location: any,
  submitlogin: any,
  intl: any,
  tab: any,
  loginError: any,
  forgotpassSucess: any,
  forgotpassError: any,
  forgotPass: any,
}

const AuthenticationPageComponent: React.FC<Props & RouteComponentProps<any>> = (props: Props) => {
  const {
    intl,
    isAuth,
    // location,
    scrollingDisabled,
    tab,
    submitlogin,
    loginError,
    forgotPass,
    forgotpassSucess,
    forgotpassError,
  } = props;

  // const authError = Cookies.get('st-autherror');
  // const authInfo = Cookies.get('st-authinfo');
  const [resetPassword, setResetPassword] = useState(false);
  const submitLogin = (values: any) => {
    if (values.remember) {
      Cookies.set('email_authInfo', values.email);
      Cookies.set('password_authInfo', values.password);
    } else {
      Cookies.remove('email_authInfo');
      Cookies.remove('password_authInfo');
    }
    const data = aesEncrypted(JSON.stringify({
      email: values.email,
      password: values.password,
      deviceId: 'string',
      deviceToken: 'string',
    }));
    submitlogin(data);
  };
  const handleForgotPassword = (values: any) => {
    forgotPass(values);
  };

  // const isLogin = tab === 'login';
  // const [state, setState] = useState({
  //   tosModalOpen: false,
  //   authError: authError
  //     ? JSON.parse(authError.replace('j:', ''))
  //     : null,
  //   authInfo: authInfo
  //     ? JSON.parse(authInfo.replace('j:', ''))
  //     : null,
  // });

  // const locationFrom = location.state && location.state.from ? location.state.from : null;
  // const authinfoFrom = state.authInfo && state.authInfo.from
  //   ? state.authInfo.from
  //   : null;
  // const from = locationFrom || (authinfoFrom || null);

  // Already authenticated, redirect away from auth page

  if (isAuth) {
    AuthService.authRequest();
    return <NamedRedirect name="Dashboard" />;
    // return <Redirect to={from} />;
  }
  // if (isAuth) {
  //   return <NamedRedirect name="Dashboard" />;
  // }

  const handleResetPassword = (data: any, resetToken: any) => {
    const values = aesEncrypted(JSON.stringify({
      resetToken: resetToken.resetToken,
      password: data.password,
    }));
    AuthService.resetPassword({
      data: values,
    }).then(() => {
      setResetPassword(true);
    });
  };

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );
  const errorMessage = (error: any, message: any) => (error ? message : null);
  const loginOrSignupError = errorMessage('', loginErrorMessage);

  // const authenticationForms = (isLogin
  //   ? (
  //     <div className={css.content}>
  //       {loginOrSignupError}
  //       <Login
  //         handleSubmit={submitLogin}
  //         loginError={loginError}
  //         changeSuccessPass={changeSuccessPass}
  //       />
  //     </div>
  //   ) : (
  //     <div className={css.content}>
  //       <ForgotPassword
  //         handleSubmit={handleForgotPassword}
  //         forgotpassSucess={forgotpassSucess}
  //         forgotpassError={forgotpassError}
  //       />
  //     </div>
  //   )
  // );

  const authenticationForms = (
    <>
      {
        tab === 'login'
        && (
          <div className={css.content}>
            {loginOrSignupError}
            <Login
              handleSubmit={submitLogin}
              loginError={loginError}
              changeSuccessPass={changeSuccessPass}
            />
          </div>
        )
      }
      {
        tab === 'forgotpassword'
        && (
          <div className={css.content}>
            <ForgotPassword
              handleSubmit={handleForgotPassword}
              forgotpassSucess={forgotpassSucess}
              forgotpassError={forgotpassError}
            />
          </div>
        )
      }
      {
        tab === 'resetPassword'
        && (
          <div className={css.content}>
            <ResetPasswordForm
              handleResetPassword={handleResetPassword}
              resetPassword={resetPassword}
            />
          </div>
        )
      }
    </>

  );

  const formContent = authenticationForms;

  const { siteTitle } = config;

  const schemaTitle = () => {
    if (tab === 'login') {
      return intl.formatMessage(
        { id: 'AuthenticationPage.schemaTitleLogin' },
        { siteTitle },
      );
    }
    if (tab === 'forgotpassword') {
      return intl.formatMessage(
        { id: 'AuthenticationPage.ForgotPassword' },
        { siteTitle },
      );
    }
    if (tab === 'resetPassword') {
      return intl.formatMessage(
        { id: 'AuthenticationPage.ResetPassword' },
        { siteTitle },
      );
    }
    return null;
  };

  return (
    <Page
      title={schemaTitle()}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <div className={css.row}>
        <div className={css.column}>
          <img src={avtausLogo} alt="avatus logo" />
        </div>
        <div className={css.column}>
          <div className={css.container}>
            {formContent}
          </div>
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = (state: any) => {
  const { auth } = state;
  return {
    isAuth: auth.isAuthenticated,
    loginError: auth.loginError,
    forgotpassSucess: auth.forgotpassSucess,
    forgotpassError: auth.forgotpassError,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  submitlogin: (data: any) => dispatch(loginRequest(data)),
  forgotPass: (data: any) => dispatch(forgotPassword(data)),
  changeSuccessPass: () => dispatch(changeSuccessPass()),
});

const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(AuthenticationPageComponent);

export default AuthenticationPage;
