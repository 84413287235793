import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  FieldFileInput,
  FieldSelect,
  FieldTextInput,
  ValidationError,
  CircularProgressLoader,
} from 'components';
import { ChannelManagementService } from 'services';
import { FormattedMessage } from 'utils/reactIntl';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import scss from './AddSubDomes.module.scss';
import information from '../../assets/svg/info.svg';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  handleSubmit: any;
  handleSaveAsDraft: any;
  error: any;
  loader: any;
}

const AddSubDomesForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit, loader, handleSaveAsDraft, error, intl,
  } = props;
  const [domes, setDomes] = React.useState([]);
  const FILE_SIZE = 50 * 1024 * 1024;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
  ];
  const addSubDomesSchema = yup.object().shape({
    domes: yup.string().required('Please fill all the required fields'),
    domeName: yup.string()
      .required('Please fill all required fields'),
    description: yup.string()
      .max(250, 'Please enter characters less than 250 characters for description')
      .required('Please fill all required fields'),
    selectedIcon: yup
      .mixed()
      .required('Please fill all required fields')
      .test(
        'fileSize',
        'Selected Icon File too large',
        (value) => value && value[0].size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        'Unsupported Format for Selected icon.(jpeg/png is required)',
        (value) => value && SUPPORTED_FORMATS.includes(value[0].type),
      ),
    unselectedIcon: yup.mixed()
      .required('Please fill all required fields')
      .test(
        'fileSize',
        'Unselected Icon File too large',
        (value) => value && value[0].size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        'Unsupported Format for Unselected icon.(jpeg/png is required)',
        (value) => value && SUPPORTED_FORMATS.includes(value[0].type),
      ),
  }, []);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    ChannelManagementService.getDomes().then((data) => {
      const domesData: any = [];
      if (data.data.data !== undefined) {
        data.data.data.forEach((ele: any) => {
          // eslint-disable-next-line no-underscore-dangle
          domesData.push({ key: ele._id, label: ele.name });
        });
      }
      setDomes(domesData);
      setLoading(false);
    });
  }, []);

  const formProps = useForm({
    resolver: yupResolver(addSubDomesSchema),
  });

  return (
    <>
      {loading ? (
        <div>
          <CircularProgressLoader />
        </div>
      ) : (
        <div className={classNames(commonStyles.cardBg, scss.container)}>
          <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleSubmit)}>
            <h2 className={scss.sign_text}>
              <FormattedMessage id="AddSubDomes.label.details" />
            </h2>
            <div className="flex_row">
              <div className="flex_col_sm_6">
                <div className={scss.userType}>
                  <FieldSelect
                    id="domes"
                    name="domes"
                    label={intl.formatMessage({ id: 'AddSubDomes.label.domes' })}
                    variant={false}
                    formProps={formProps}
                    options={domes}
                  />
                </div>
              </div>
              <div className="flex_col_sm_6">
                <FieldTextInput
                  id="domeName"
                  name="domeName"
                  formName="addDomes"
                  className="textInput"
                  type="text"
                  formProps={formProps}
                  rootClassName={scss.passwordInput}
                  label={intl.formatMessage({ id: 'AddSubDomes.label.subDomes' })}
                />
              </div>
              <div className="flex_col_sm_12">
                <FieldTextInput
                  id="description"
                  name="description"
                  formName="addDomes"
                  className="textInput"
                  type="textarea"
                  formProps={formProps}
                  rootClassName={scss.passwordInput}
                  label={intl.formatMessage({ id: 'AddSubDomes.label.description' })}
                />
              </div>
            </div>
            <h2 className={scss.sign_text}>
              <FormattedMessage id="AddSubDomes.label.uploadIcons" />
            </h2>
            <div className={scss.profileWrapperimg}>
              <div className={scss.selection_box}>
                <h4>
                  <FormattedMessage id="AddSubDomes.label.selectedIcons" />
                </h4>
                <FieldFileInput
                  id="selectedIcon"
                  name="selectedIcon"
                  className="textInput"
                  formProps={formProps}
                  rootClassName={scss.input_upload}
                />
              </div>
              <div className={scss.selection_box}>
                <h4>
                  <FormattedMessage id="AddSubDomes.label.unselectedIcons" />
                </h4>
                <FieldFileInput
                  id="unselectedIcon"
                  name="unselectedIcon"
                  className="textInput"
                  formProps={formProps}
                  rootClassName={scss.input_upload}
                />
              </div>
            </div>
            <p className={scss.informationUpload}>
              <img src={information} alt="information" />
              <FormattedMessage id="AddSubDomes.label.size" />
            </p>
            {Object.keys(formProps.formState.errors).length > 0
              && (
                <ValidationError
                  className={scss.validation_error}
                  error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
                />
              )}
            {Object.keys(formProps.formState.errors).length === 0
              && <ValidationError error={error} />}
            {loader ? <div className={commonStyles.savingLoader}><CircularProgressLoader /></div> : (
              <>
                <div className={scss.buttonWrapper}>
                  <Button onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}>
                    <FormattedMessage id="AddSubDomes.label.save.as.draft" />
                  </Button>
                  <Button buttonType={ButtonTypes.submit}>
                    <FormattedMessage id="AddSubDomes.label.publish" />
                  </Button>
                </div>
              </>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(AddSubDomesForm);
