import { Button } from 'components';
import React from 'react';
import { PriceManagementService } from 'services';
import warning from '../../assets/svg/warning.svg';

interface Props {
  handleModalClose: any;
  id: any;
  fetchPriceList: any;
  status: any;
}
const PriceManagementBlocked: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    fetchPriceList,
    status,
  } = props;

  const [blocked, setBlocked] = React.useState(false);
  const blockService = () => {
    PriceManagementService.blockPriceEntry({ commissionId: id, status }).then(() => {
      setBlocked(true);
    });
  };

  return (
    <>
      {!blocked ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            {status === 'blocked' ? 'Block' : 'Unblock'}
            {' '}
          </h2>
          <p>
            Are you sure you want to
            {' '}
            {status === 'blocked' ? 'block' : 'unblock'}
            {' '}
            this entry?
          </p>
          <div>
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={blockService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Entry
            {' '}
            {status === 'blocked' ? 'blocked' : 'unblocked'}
            {' '}
            successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchPriceList();
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default PriceManagementBlocked;
